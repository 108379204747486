/*
 * File: google.ts                                                             *
 * Project: @nousmedis/guard-front                                             *
 * File Created: Thursday, 9th March 2023 17:16:10                             *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Thursday, 9th March 2023 17:17:23                            *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2023 Nousmedis, CeGe                                       *
 */

import { GoogleLoginProvider, SocialAuthServiceConfig } from "@abacritt/angularx-social-login";

export const googleProvider: SocialAuthServiceConfig = {
    autoLogin: false,
    providers: [
        {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
                "13388866202-puh9d5jupc4ujo090gk72teseufo42bq.apps.googleusercontent.com"
            ),
        },
    ],
    onError: (err) => {
        console.log(err);
    },
};
