import { FormSchema } from "../../form-generator/interfaces/form-schema.interface";

export const AppForm: FormSchema = {
  groups: [
    {
      groupName: "app",
      groupLabel: "App",
      showLabel: false,
      fields: [
        {
          type: "hidden",
          label: "ID",
          value: "",
          key: "id",
          required: true,
          order: 1,
        },
        {
          type: "text",
          label: "ID app",
          value: "",
          key: "idApp",
          required: true,
          order: 2,
        },
        {
          type: "text",
          label: "Name",
          value: "",
          key: "name",
          required: true,
          order: 3,
        },
        {
          type: "text",
          label: "Auth URL",
          value: "",
          key: "authUrl",
          required: true,
          order: 4,
        },
        {
          type: "text",
          label: "Stats Data URL",
          value: "",
          key: "statsUrl",
          required: true,
          order: 5,
        },
        {
          type: "slide",
          label: "Active",
          value: false,
          key: "active",
          required: true,
          order: 6,
        },
      ],
    },
  ],
};
