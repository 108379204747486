<div class="dash-container">
  <div class="dash-header">
    <h2>DASH</h2>
  </div>
  <div class="dash-body">
    <div class="dash-info">
      <ng-container *ngIf="roleCode == 'SUPER_ADMIN'">
        <mat-card *ngIf="blockedUsers.length > 0" class="card-dash-info">
          <mat-card-content
            ><a href="/users/list">
              <mat-icon color="warn">warning</mat-icon>
              {{ blockedUsers.length }}
              {{ "USER.BLOCKED_USERS" | translate }}</a
            ></mat-card-content
          >
        </mat-card>
        <mat-card *ngIf="pendingUsers.length > 0">
          <mat-card-content
            ><a href="/users/list">
              <mat-icon color="warn">warning</mat-icon>
              {{ pendingUsers.length }}
              {{ "USER.PENDING_APPROVAL_USERS" | translate }}</a
            ></mat-card-content
          >
        </mat-card>
      </ng-container>
    </div>

    <div class="dash-apps" *ngIf="myAppsLoaded==true">
      <div *ngFor="let app of myApps" >
        <mat-card *ngIf="app.active == true" class="card-info">
          <mat-card-header>
            <mat-card-title>{{ app.name }}</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div class="app-stats" *ngIf="app.stats">
              <span class="app-stat" *ngFor="let stat of app.stats.stats">
                {{ stat.label | translate }} : <b>{{ stat.value }}</b>
              </span>
              <!-- <span class="app-stat">Auth Url {{app.authUrl}}</span> -->
            </div>
          </mat-card-content>
          <mat-card-actions class="app-actions">
            <a
              mat-raised-button
              target="_blank"
              href="{{ app.appLink }}"
              color="primary"
              >Go to {{ app.name }}</a
            >
          </mat-card-actions>
        </mat-card>
      </div>
    </div>
  </div>
</div>
