/*
 * File: side-menu.component.ts                                                *
 * Project: core                                                      *
 * File Created: Wednesday, 22nd December 2021 12:48:17                        *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Friday, 17th June 2022 11:15:26                              *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */

import { Component, Input, OnInit } from "@angular/core";
import { IUser } from "src/app/modules/user/interfaces/user.interface";
import { Router } from "@angular/router";
import { ActionsMenuService } from "../../services/actionsMenu.service";
import { ModalUserForms } from "src/app/modules/user/helpers/modal-user-forms.helper";
import { TranslateService } from "@ngx-translate/core";
import { MatDialog } from "@angular/material/dialog";
import { ApiService } from "src/app/modules/api/services/api.service";
import { CookieService } from "ngx-cookie-service";
import { UserModel } from "src/app/modules/user/models/user.model";
import { UserService } from "src/app/modules/user/services/user.service";
import { PopInfoService } from "src/app/modules/pop-info/services/pop-info-service.service";
import { AuthModel } from "src/app/modules/user/models/auth.model";

export enum checkStatus {
    Zero = 0,
    One = 1,
    moreThanOne = 2,
}

@Component({
    selector: "cc-actions-menu",
    templateUrl: "./actions-menu.component.html",
    styleUrls: ["./actions-menu.component.scss"],
})
export class ActionsMenuComponent implements OnInit {
    @Input() clickedItem: string = "";
    @Input() context: string = "";

    events: string[] = [];
    isExpanded: boolean = false;
    toogleIcon = "arrow_right";
    isVisible: boolean = false;

    downloadJsonHref;

    user: IUser;
    inconMenu = "hail";
    sidenavIsExpanded: boolean = false;

    actionsBunttos: any[];
    fascicleButtons: any[];

    /**
     * Checks control
     */
    checkControl: checkStatus = checkStatus.Zero;
    userForms: ModalUserForms;
    userModel: UserModel;
    auth:AuthModel;

    constructor(
        private actionsService: ActionsMenuService,
        private router: Router,
        translate: TranslateService,
        dialog: MatDialog,
        api: ApiService,
        cookie: CookieService,
        popup: PopInfoService,
        userSrv: UserService
    ) {
        this.userForms = new ModalUserForms(
            dialog,
            translate,
            popup,
            api,
            cookie,
            userSrv
        );
        this.userModel = new UserModel(api, cookie);
        this.auth = new AuthModel(api,cookie);
    }

    ngOnInit(): void {
        this.user = this.auth.getCurrentUser();
        this.setButtons();
        this.expandSideNav();
        this.checkRows();
        this.listenForCheckItem();
    }

    checkRows() {
        this.actionsService.totalRowsChecked.subscribe((totalRows) => {
            this.setListBehaviour(totalRows);
            this.setButtons();
        });
    }

    listenForCheckItem() {
        this.actionsService.activeItem.subscribe((item: string) => {
            this.clickedItem = item;
        });
    }

    expandSideNav() {
        this.actionsService.change.subscribe((isOpen) => {
            this.isExpanded = isOpen;
            if (isOpen) this.toogleIcon = "arrow_left";
            else this.toogleIcon = "arrow_right";
        });
    }

    setListBehaviour(totalRows: number) {
        if (totalRows == 0) {
            this.checkControl = checkStatus.Zero;
        } else if (totalRows == 1) {
            this.checkControl = checkStatus.One;
        } else {
            this.checkControl = checkStatus.moreThanOne;
        }
    }

    toogleSideNav() {
        this.sidenavIsExpanded = this.sidenavIsExpanded ? false : true;
        if (this.inconMenu == "menu") this.inconMenu = "close";
        else this.inconMenu = "menu";
        this.actionsService.toggle();
    }

    goToHome() {
        this.router.navigate(["/"]);
    }

    newPageTempalte() {
        this.router.navigate(["/page-templates/new"]);
    }

    setButtons() {
        const userButtons = [
            {
                name: "addUser",
                disabled: false,
                active: true,
                clickButton: () => this.userForms.registerForm(),
                tooltip: "MENU.ADD_USER",
                tooltipPostion: "right",
                icon: "person_add",
                type: "button",
            },
        ];

        const cronMenuButtons = [
            {
                name: "addUser",
                disabled: false,
                active: true,
                clickButton: () => this.userForms.registerForm(),
                tooltip: "MENU.ADD_USER",
                tooltipPostion: "right",
                icon: "person_add",
                type: "button",
            },
        ];

        switch (this.context) {
            case "user": {
                this.actionsBunttos = userButtons;
                this.isVisible = true;
                break;
            }

            case "cron-menu": {
                this.actionsBunttos = cronMenuButtons;
                this.isVisible = true;
                break;
            }
            default:
                this.isVisible = false;
                break;
        }
    }
}
