<!--
File: menu.component.html
Project: catalog-cloud
File Created: Wednesday, 22nd December 2021 12:48:17
Author: Tomás Muniesa (tomas@cege.es)
-----
Last Modified:  Monday, 24th July 2023 2:12:34 pm
Modified By: Tomás (tomas@cege.es>)
-----
Copyright 2021 - 2022 Nousmedis, CeGe
-->

<button
  mat-flat-button
  [matMenuTriggerFor]="menu"
  aria-label="user-menu-button"
>
  <!-- <mat-icon matPrefix>settings</mat-icon> -->
  <span> {{ user.name }} {{ user.surname }}</span>
  <mat-icon matSuffix>expand_more</mat-icon>
</button>

<mat-menu #menu="matMenu">
  <button mat-menu-item>
    <mat-icon matPrefix>person</mat-icon>
    <span>{{ user.name }}</span>
  </button>

  <button mat-menu-item (click)="editMe()">
    <mat-icon matPrefix>edit</mat-icon>
    <span>{{ "USER.EDIT_MY_DATA" | translate }} </span>
  </button>

  <button
    mat-menu-item
    *ngIf="roleCode == 'SUPER_ADMIN'"
    [routerLink]="'/users/list/'"
  >
    <mat-icon matPrefix>people_black</mat-icon>
    <span>{{ "USER.ADMIN_USERS" | translate }} </span>
  </button>

  <mat-divider *ngIf="roleCode == 'SUPER_ADMIN'"> </mat-divider>

  <mat-divider *ngIf="roleCode == 'SUPER_ADMIN'"></mat-divider>
  <button mat-menu-item (click)="logout()">
    <mat-icon>logout</mat-icon>
    <span>{{ "USER.CLOSE_SESSION" | translate }} </span>
  </button>
</mat-menu>
