<div  class="apps-container">
    <div fxLayout="row" fxLayoutAlign="start start" class="apps-buttons">
      <button mat-raised-button color="primary" (click)="newApp()">
        New App
      </button>
    </div>
    <div fxLayout="row" fxLayoutAlign="start start" fxFlex="100">
      <app-app-list fxFlex="50"></app-app-list>
      <app-app-form fxFlex="40" style="margin-left: 4em;"> </app-app-form>
    </div>
  </div>
  