<!--
 * File: input.component.html                                                  *
 * Project: @nousmedis/syncwall-frontend                                       *
 * File Created: Tuesday, 14th February 2023 15:37:52                          *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Tuesday, 14th February 2023 18:20:47                         *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2023 Nousmedis, CeGe                                       *
 -->

<mat-form-field appearance="outline" class="full-witdth" [formGroup]="formGroup">
    <mat-label>{{ label | translate }}</mat-label>
    <input
        matInput
        type="text"
        
        [formControlName]="key"
        value="{{ value }}"
        autocomplete="{{ autocomplete }}"
        autocapitalize="{{ autocapitalize }}"
        spellcheck="{{ spellCheck }}"
    />
    <mat-icon
        *ngIf="!formGroup.controls[key].valid"
        matSuffix
        class="field-error"
        >error</mat-icon
    >
</mat-form-field>
