<!--
 * File: edit.component.html                                                   *
 * Project: @nousmedis/syncwall-frontend                                       *
 * File Created: Tuesday, 15th March 2022 11:52:45                             *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Friday, 24th February 2023 13:09:08                          *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 -->
<div fxLayout="row" fxLayoutAlign="space-around center" *ngIf="show" class="form-edit-user mat-elevation-z8">
    <app-generate-form [jsonForm]="orderedMainFields"></app-generate-form>

    <!-- <form *ngIf="fc.form" autocomplete="off" class="base-fascicle-form">
        <div class="full-width" *ngIf="formDataIsLoaded">
            <app-field-generator
                *ngFor="let field of orderedMainFields"
                [formGroup]="fbMain"
                [field]="field"
            ></app-field-generator>
        </div>
    </form> -->
</div>
<!-- <button (click)="findInvalidControls()">Invalidos</button> -->