<!--
File: top-menu.component.html
Project: catalog-cloud
File Created: Wednesday, 22nd December 2021 12:48:17
Author: Tomás Muniesa (tomas@cege.es)
-----
Last Modified:  Tuesday, 11th July 2023 3:06:30 pm
Modified By: Tomás (tomas@cege.es>)
-----
Copyright 2021 - 2022 Nousmedis, CeGe
-->

<mat-toolbar class="top-toolbar">
  <mat-toolbar-row style="padding-left: 0em !important" class="top-toolbar-row">
    <!-- <button class="toogle-sidenav" (click)="toogleSideNav()">
      <mat-icon
        [ngClass]="sidenavIsExpanded ? 'collapse-sidemenu' : 'expand-sidemenu'"
        >menu</mat-icon
      >
    </button> -->
    <div class="top-logo" fxLayout="row" fxLayoutAlign="center center">
      <img [routerLink]="'/'" [src]="'/assets/img/logo_nm_dark.png'"/>  
    </div>
    

    <cc-actions-menu [context]="context"></cc-actions-menu>
    <!-- <app-organitzation-selector
      fxFlex
      fxLayoutAlign="flex-end center"
    ></app-organitzation-selector> -->
    <user-options-side-menu
      *ngIf="this.user"
      [user]="user"
      fxFlex
      fxLayoutAlign="flex-end center"
    ></user-options-side-menu>
  </mat-toolbar-row>
</mat-toolbar>
