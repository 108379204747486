/*
 * File: app-routing.module.ts                                                 *
 * Project: @nousmedis/guard-front                                             *
 * File Created: Wednesday, 22nd December 2021 12:48:17                        *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Friday, 10th March 2023 14:06:53                             *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */

import { Component, NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { NotFoundComponent } from "./modules/tms-tools/components/not-found/not-found.component";
import { UserLoginComponent } from "./modules/user/components/user-login/user-login.component";
import { UserResetPasswordComponent } from "./modules/user/components/user-reset-password/user-reset-password.component";
import { LoginActivate } from "./modules/user/helpers/login-activate.helper";

import { RolesComponent } from "./modules/nm-apps/components/roles/roles.component";
import { AppsComponent } from "./modules/nm-apps/components/apps/apps.component";
import { UserListComponent } from "./modules/user/components/user-list/user-list.component";
import { LandingPageComponent } from "./modules/landing-page/landing-page.component";
import { OrganitzationsComponent } from "./modules/nm-apps/components/organitzations/organitzations.component";

const routes: Routes = [
  {
    path: "",
    redirectTo: "/dash",
    pathMatch: "full",
  },
  {
    path: "dash",
    canActivate: [LoginActivate],
    component: LandingPageComponent,
    title: "Dash Board",
  },
  {
    path: "apps",
    canActivate: [LoginActivate],
    title: "Apps",
    component: AppsComponent,
    // children:[
    //     {
    //         path:"list",
    //         canActivate:[LoginActivate],
    //         component:AppListComponent,
    //         title:"App List"
    //     },
    //     {
    //         path:"new",
    //         canActivate:[LoginActivate],
    //         component:FormComponent,
    //         title:"New App"
    //     },
    //     {
    //         path:"edit/:id",
    //         canActivate:[LoginActivate],
    //         component:FormComponent,
    //         title:"New App"
    //     }
    // ]
  },
  {
    path: "roles",
    canActivate: [LoginActivate],
    title: "Roles",
    component: RolesComponent,
  },
  {
    path: "organitzations",
    canActivate: [LoginActivate],
    title: "Organizations",
    component: OrganitzationsComponent,
  },
  {
    path: "reset-password",
    component: UserResetPasswordComponent,
    title: "NousMedis - Reset Password",
  },
  {
    path: "login",
    component: UserLoginComponent,
    title: "NousMedis - Login",
  },
  {
    path: "users",
    canActivate: [LoginActivate],
    data: {
      breadcrumb: "users",
    },
    title: "NousMedis - Users",
    children: [
      {
        path: "list",
        data: {
          breadcrumb: "User List",
        },
        canActivate: [LoginActivate],
        component: UserListComponent,
      },
    ],
  },
  {
    path: "**",
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [LoginActivate],
})
export class AppRoutingModule {}
