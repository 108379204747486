/*
 * File: http-error.interceptor.ts                                             *
 * Project: @nousmedis/syncwall-frontend                                       *
 * File Created: Thursday, 17th February 2022 11:48:25                         *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Tuesday, 21st February 2023 10:26:37                         *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */

import { Injectable } from "@angular/core";
import {
    HttpHandler,
    HttpRequest,
    HttpInterceptor,
} from "@angular/common/http";
import { throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { PopInfoService } from "../../pop-info/services/pop-info-service.service";
import { AuthModel } from "../../user/models/auth.model";
import { ApiService } from "../services/api.service";
import { CookieService } from "ngx-cookie-service";

@Injectable({
    providedIn: "root",
})
export class HttpErrorInterceptor implements HttpInterceptor {
    private pop: PopInfoService;
    constructor(
        private popService: PopInfoService,
        private apiService: ApiService,
        private cookies: CookieService
    ) {
        this.pop = popService;
    }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        return next.handle(req).pipe(
            catchError((error) => {
                let errorMessage = "";
                if (typeof error === "string") {
                    this.popService.addMessage("warn", error, "Api Error");
                } else {
                    if (error instanceof ErrorEvent) {
                        // client-side error
                        errorMessage = `Client-side error: ${error.error.message}`;
                    } else {
                        // backend error
                        console.log(error.error);
                        if (
                            error.status == 401 &&
                            error.error === "Token-Expired"
                        ) {
                            const auth = new AuthModel(
                                this.apiService,
                                this.cookies
                            );

                            auth.refreshToken().subscribe((data) => {
                              console.log(data);
                                auth.setJwsTokenCookie(data.token);
                            });
                        } else if (
                            error.status == 401 &&
                            error.error === "Refresh token Expired"
                        ) {
                            const auth = new AuthModel(
                                this.apiService,
                                this.cookies
                            );
                            auth.resetCookie();
                        }
                        errorMessage = `Server-side error: ${error.status} -- ${error.message} `;
                    }
                    this.popService.addMessage('warn', errorMessage, 'Api Error');
                }
                

                console.log(error);
                // aquí se podría agregar código que muestre el error en alguna parte fija de la pantalla.
                // if (environment.production== false){
                //   this.popService.addMessage('warn',errorMessage,'Api Error');
                //   console.log(error);
                // }
                return throwError(errorMessage);
            })
        );
    }
}
