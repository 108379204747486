import { Component, OnInit } from '@angular/core';
import { AppsService } from '../../services/apps.service';

@Component({
  selector: 'app-apps',
  templateUrl: './apps.component.html',
  styleUrls: ['./apps.component.scss']
})
export class AppsComponent implements OnInit {

  constructor(private appService:AppsService) { }

  ngOnInit(): void {
    this.appService.init();
  }
  newApp(){
    this.appService.init();
  }
}
