/*
 * File: select.component.ts                                                   *
 * Project: @nousmedis/syncwall-frontend                                       *
 * File Created: Tuesday, 14th February 2023 15:37:31                          *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Tuesday, 21st February 2023 11:56:49                         *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2023 Nousmedis, CeGe                                       *
 */



import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent implements OnInit {
  @Input() formGroup:FormGroup
  @Input() appearance:string = "outline";
  @Input() selectedValue:string = "";
  @Input() selectValues:any[] = []; 
  @Input() fcName:string;
  @Input() key:string;
  @Input() class:string = "full-width"
  @Input() label:string;
  @Input() autocomplete:string ="off";
  @Input() autocapitalize:string = "off";
  @Input() spellCheck:string ="false";
  @Input() classError:string = "field-error"
  @Input() errorIcon:string = " error"
  constructor() { }

  ngOnInit(): void {
  }

}
