import { Injectable } from "@angular/core";
import { Observable, Observer, Subject, firstValueFrom } from "rxjs";
import { ApiService } from "../../api/services/api.service";

import { appToken } from "../interfaces/app-token.interface";

@Injectable({
  providedIn: "root",
})
export class AppTokenService {
  endPoints = {
    get: "app-token/",
    getByAppAndUser: "app-token/app/{1}/user/{2}",
    create: "app-token/",
    delete: "app-token/",
  };
  constructor(private api: ApiService) {}

  loading: Subject<boolean> = new Subject<boolean>();
  $appToken: Subject<appToken> = new Subject<appToken>();

  init(idApp: string) {
    let appToken: appToken = {
      id: "",
      idApp: idApp,
      idUser: "",
      createdBy: "",
    };
    this.$appToken.next(appToken);
  }

  //Get appToken by id
  getById(id: string): void {
    this.loading.next(true);
    this.api.get(this.endPoints.get + id).subscribe((data) => {
      this.loading.next(false);
      this.$appToken.next(data);
    });
  }

  //Remove appToken by id
  remove(id: string): void {
    this.loading.next(true);
    this.api.delete(this.endPoints.delete + id).subscribe((data) => {
      this.loading.next(false);
      //   this.$appToken.next(data);
    });
  }

  //Create appToken
  async create(appToken: appToken): Promise<void> {
    console.log("CREATING APP TOKEN");
    this.loading.next(true);
    let token = await firstValueFrom(
      this.api.post(this.endPoints.create, appToken)
    );
    this.loading.next(false);
    this.$appToken.next(token);
    console.log("APP TOKEN:",token);
    return token;
  }

  //Generate App Token
  async generateToken(idApp: string, idUser: string) {
    try {
      let appToken = await firstValueFrom(
        this.api.get(
          this.endPoints.getByAppAndUser
            .replace("{1}", idApp)
            .replace("{2}", idUser)
        )
      );
      if (appToken) {
        await this.remove(appToken.id);
      }
      appToken = {
        id: "",
        idApp: idApp,
        idUser: idUser,
        createdBy: "",
      };
      appToken = await this.create(appToken);
      return Promise.resolve(appToken);
    } catch (ex: any) {
      return Promise.reject(ex.toString());
    }
  }
}
