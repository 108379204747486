import {
  Component,
  DoCheck,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { tmsTable } from "../../interfaces/table.interface";
import {tmsTableColumn } from "../../interfaces/table-column.interface"
@Component({
  selector: "app-table-generator",
  templateUrl: "./table-generator.component.html",
  styleUrls: ["./table-generator.component.scss"],
})
export class TableGeneratorComponent implements OnChanges {
  @Input() tableData: tmsTable = {
    name: "Table",
    columns: [],
    data: []
  };
  displayedColumns: string[] = [];
  columns: tmsTableColumn[] = [];
  data: any[] = [];
  tableName: string = "";

  tableLoading: boolean = true;

  constructor() {}

  // ngOnInit(): void {
  //   this.settingData();
  // }

  settingData() {}

  ngOnChanges(changes: SimpleChanges): void {
    this.tableLoading = true;
    const newTableData = changes.tableData.currentValue;
    this.data = changes.tableData.currentValue.data;
    this.columns = changes.tableData.currentValue.columns;
    this.tableName = changes.tableData.currentValue.name;
    this.displayedColumns = this.tableData.columns.filter(c => c.visible == true).map((column) => column.name);
    this.tableLoading = false;
    this.settingData();
  }
}
