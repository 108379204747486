<!--
 * File: modal-dialog.component.html                                           *
 * Project: @nousmedis/syncwall-frontend                                       *
 * File Created: Thursday, 17th February 2022 11:48:25                         *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Tuesday, 21st February 2023 12:04:21                         *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 -->

<h2 class="dialog-headline">{{ title }}</h2>

<div fxFill fxLayoutAlign="end end" class="dialog-close">
    <span *ngIf="type === 'cancel'"
        ><button mat-icon-button matDialogClose (click)="onNoClick()">
            <mat-icon style="color: #666">cancel</mat-icon>
        </button>
    </span>
</div>

<mat-dialog-content class="dialog-content">
    <ng-template #target></ng-template>
    <mat-progress-bar
        *ngIf="waiter"
        color="primary"
        mode="determinate"
        [value]="progessValue"
    ></mat-progress-bar>
</mat-dialog-content>

<mat-dialog-actions class="dialog-actions" *ngIf="loaded && type == 'form'">
    <div fxFill fxLayout="row" fxLayoutAlign="end none">
        <button mat-raised-button matDialogClose (click)="onNoClick()">
            {{ "FORM.CANCEL" | translate }}
        </button>
        <button
            mat-raised-button
            matDialogClose
            color="primary"
            (click)="okClick()"
            [disabled]="componentRef.instance.form.status === 'INVALID'"
        >
            {{ "FORM.SAVE" | translate }}
        </button>
    </div>
</mat-dialog-actions>

<mat-dialog-actions class="dialog-actions" *ngIf="type == 'yesno'">
    <div fxFill fxLayout="row" fxLayoutAlign="center none">
        <button mat-raised-button matDialogClose (click)="onNoClick()">
            {{ "FORM.NO" | translate }}
        </button>
        <button
            mat-raised-button
            matDialogClose
            color="primary"
            (click)="okClick()"
        >
            {{ "FORM.YES" | translate }}
        </button>
    </div>
</mat-dialog-actions>

<!-- <mat-dialog-actions class="dialog-actions" *ngIf="type=='cancel'">
    <div fxFill fxLayout="row" fxLayoutAlign="end none" >

  </mat-dialog-actions> -->

<mat-dialog-actions class="dialog-actions" *ngIf="loaded && type == 'entity'">
    <div fxFill fxLayout="row" fxLayoutAlign="end none">
        <button mat-raised-button matDialogClose (click)="onNoClick()">
            {{ "FORM.CANCEL" | translate }}
        </button>
        <button
            mat-raised-button
            matDialogClose
            color="primary"
            (click)="okClick()"
        >
            {{ "FORM.SAVE" | translate }}
        </button>
    </div>
</mat-dialog-actions>
