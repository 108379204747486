/*
 * File: actionsMenu.service.ts                                                *
 * Project: catalog-cloud                                                      *
 * File Created: Thursday, 17th February 2022 11:48:25                         *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Friday, 17th June 2022 10:54:48                              *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */



import { EventEmitter, Injectable, Output } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ActionsMenuService {
  constructor() {}

  private expanded:boolean;
  @Output() change: EventEmitter<boolean> = new EventEmitter();
  @Output() totalRowsChecked: EventEmitter<number> = new EventEmitter();
  @Output() currentCatalogId: string = '';

  isExpanded:Subject<boolean> = new Subject<boolean>();

  currentItem = '';
  activeItem:Subject<string> = new Subject<string>();




  public updateRowsChecked(total:number)
  {
    this.totalRowsChecked.emit(total);
  }

  public setCurrentCalaogSelected(id:string)
  {
    this.currentCatalogId = id;
  }

  public setMenu(item:string){
    return this.activeItem.next(item);
  }

  public reset()
  {
    this.setCurrentCalaogSelected('');
    this.updateRowsChecked(0);
    this.setMenu('');
    this.activeItem.next('');
  }


  public toggle(): void {
    this.expanded = !this.expanded;
    this.change.emit(this.expanded);
    this.isExpanded.next(this.expanded);
    //this.sidenav.toggle();
  }
}
