/*
 * File: user-register.schema.ts                                               *
 * Project: @nousmedis/syncwall-frontend                                       *
 * File Created: Thursday, 3rd March 2022 17:37:21                             *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Friday, 24th February 2023 13:56:41                          *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */

import { FormSchema } from "src/app/modules/form-generator/interfaces/form-schema.interface";
import { langs } from "src/assets/i18n/available-lang";
import { mailValidator } from "../../form-generator/validators/email.validator";

//Estaria bien que esto fuera ina interface
export const userEditSchema: FormSchema = {
  groups: [
    {
      groupName: "user",
      groupLabel: "Usuario",
      showLabel: true,
      fields: [
        {
          key: "id",
          type: "hidden",
          value: "false",
          label: "",
          required: true,
          order: 1,
          data: null,
        },
        {
          key: "name",
          type: "text",
          value: "",
          label: "NAME",
          required: true,
          order: 2,
          data: null,
        },
        {
          key: "surname",
          type: "text",
          value: "",
          label: "USER.SURNAME",
          required: true,
          order: 3,
          data: null,
        },
        {
          key: "username",
          type: "text",
          value: "",
          label: "USER.EMAIL",
          required: true,
          order: 4,
          data: null,
          validators: [mailValidator]
        },
        {
          key: "lang",
          type: "select",
          value: "",
          label: "USER.SELECT_LANG",
          required: true,
          order: 5,
          data: {
            base: langs.map((l) => {
              return { label: l.lang, value: l.value };
            }),
            selected: "lang",
          }
        },
        {
          key: "role",
          type: "select",
          value: "",
          label: "ROLE",
          required: false,
          order: 6,
          data: {
            
          },
      },
      ],
    },
  ],
};

