import { Injectable } from "@angular/core";
import { Observable, Observer, Subject } from "rxjs";
import { ApiService } from "../../api/services/api.service";
import { AppUser } from "../interfaces/app-user.interface";

@Injectable({
  providedIn: "root",
})
export class AppUserService {
  endPoints = {
    get: "app-user/",
    getByApp: "app-user/by-app/",
    getByUser:"app-user/by-user/",
    getAppByIdAndUser: "app-user/user/",
    update: "app-user/",
    create: "app-user/",
    delete: "app-user/",
    deleteByIdAndUser: "app-user/user/",
  };

  loading: Subject<boolean> = new Subject<boolean>();
  $appsUsers: Subject<AppUser[]> = new Subject<AppUser[]>();
  $appUser: Subject<AppUser> = new Subject<AppUser>();
  $appsUser:Subject<AppUser[]> = new Subject<AppUser[]>();
  $dataUpdated: Subject<boolean> = new Subject<boolean>();

  constructor(private api: ApiService) {}

  init(idApp: string) {
    let appUser: AppUser = {
      id: "",
      idApp: idApp,
      idUser: "",
      idRole: "",
      createdBy: "",
    };
    this.$appUser.next(appUser);
  }

  getById(id: string): void {
    this.loading.next(true);
    this.api.get(this.endPoints.update + id).subscribe((data) => {
      this.loading.next(false);
      this.$appUser.next(data);

    });
  }

  getByAppId(idApp: string): void { 
    this.api.get(this.endPoints.getByApp + idApp).subscribe((data) => {
      this.loading.next(false);
      this.$appsUsers.next(data);
    });
  }

  getByAppIdAndUserId(idApp: string, idUser: string): void {
    this.loading.next(true);
    this.api
      .get(this.endPoints.getAppByIdAndUser + idApp + "/" + idUser)
      .subscribe((data) => {
        this.loading.next(false);
        this.$appsUsers.next(data);
      });
  }

  getByIdUser(idUser:string):void{
    this.loading.next(true);
    this.api
    .get(this.endPoints.getByUser + idUser)
    .subscribe((data) => {
      this.loading.next(false);
      this.$appsUser.next(data);
    });
  }

  update(id: string, appUser: any): void {
    this.api.patch(this.endPoints.update + id, appUser).subscribe((data) => {
      this.loading.next(false);
      this.$appUser.next(data);
      this.$dataUpdated.next(true);
    });
  }

  create(appUser: any): void {
    this.api.post(this.endPoints.create, appUser).subscribe((data) => {
      this.loading.next(false);
      this.$appUser.next(data);
      this.$dataUpdated.next(true);
    });
  }
  delete(id: string): void {
    this.api.delete(this.endPoints.delete + id).subscribe((data) => {
      this.loading.next(false);
      this.$appUser.next(data);
      this.$dataUpdated.next(true);
    });
  }
}
