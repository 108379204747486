/*
 * File: login.component.ts                                                    *
 * Project: @nousmedis/guard-front                                             *
 * File Created: Wednesday, 22nd December 2021 12:48:17                        *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Friday, 10th March 2023 14:08:07                             *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */

import { Component, Input, OnInit, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  NgForm,
  Validators,
} from "@angular/forms";
import { ErrorStateMatcher } from "@angular/material/core";
import { CookieService } from "ngx-cookie-service";

import { PopInfoService } from "src/app/modules/pop-info/services/pop-info-service.service";
import { MatCheckbox } from "@angular/material/checkbox";
import { IUser } from "src/app/modules/user/interfaces/user.interface";
import { Router } from "@angular/router";
import { UserService } from "../../services/user.service";
import { UserModel } from "../../models/user.model";
import { ApiService } from "src/app/modules/api/services/api.service";
import { AuthModel } from "../../models/auth.model";
import { TranslateService } from "@ngx-translate/core";
import {
  GoogleLoginProvider,
  SocialAuthService,
} from "@abacritt/angularx-social-login";
import { Observer, Subscription } from "rxjs";
import { RoleService } from "src/app/modules/nm-apps/services/role.service";
import { Role } from "src/app/modules/nm-apps/interfaces/role.interface";
import { AppUserService } from "src/app/modules/nm-apps/services/app-user.service";
import { AppsService } from "src/app/modules/nm-apps/services/apps.service";
import { AppTokenService } from "src/app/modules/nm-apps/services/app-token.servivce";

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

@Component({
  selector: "cc-user-login",
  templateUrl: "./user-login.component.html",
  styleUrls: ["./user-login.component.scss"],
})
export class UserLoginComponent implements OnInit {
  frm: FormGroup;
  user: IUser | undefined;
  emailFormControl = new FormControl("", [
    Validators.required,
    Validators.email,
  ]);

  passFormControl = new FormControl("", [Validators.required]);
  matcher = new MyErrorStateMatcher();
  @ViewChild("chkRemember") remember: MatCheckbox;
  // remember = new MatCheckbox(chkRem));

  msgError: string = "";
  badCredentials: boolean = false;

  hide: boolean = true;
  disable: boolean = false;

  userModel: UserModel;
  auth: AuthModel;
  googleObserver: Subscription;

  isLogin: boolean = true;
  errorLoginMessage: string = "";

  roleSubscription: Subscription;
  roles: Role[];

  appsByUser: Subscription;
  goToApp$: Subscription;

  constructor(
    private cookie: CookieService,
    private fb: FormBuilder,
    private userService: UserService,
    private route: Router,
    private popup: PopInfoService,
    private translate: TranslateService,
    private googleService: SocialAuthService,
    private roleService: RoleService,
    private appUser: AppUserService,
    private app: AppsService,
    private appTokenService: AppTokenService,

    api: ApiService
  ) {
    this.auth = new AuthModel(api, cookie);
    this.user = this.auth.getCurrentUser();
    this.userModel = new UserModel(api, cookie);
  }

  setUser() {}

  startSubscriptions() {
    this.googleObserver = this.googleService.authState.subscribe(
      async (data) => {
        let user: IUser = {
          blocked: false,
          externalId: data.id,
          id: undefined,
          _id: undefined,
          lang: "ES_es",
          name: data.firstName,
          surname: data.lastName,
          provider: data.provider,
          username: data.email,
          token: data.idToken,
          password: "",
          photo: data.photoUrl,
          role: this.roles.find((r) => r.code === "USER").id,
          approved: false,
          approvedBy: "",
          tokenProvider: data.authToken,
          refreshTokenProvider: data.authToken,
        };

        this.auth.loginProvider(user).subscribe((status) => {
          if (status) {
            if (status.login) {
              this.setLogedUSer(status.token);
            } else {
              this.isLogin = false;
              switch (status.reason) {
                case "approval_pending":
                  this.errorLoginMessage = this.translate.instant(
                    "USER.LOGIN.APPROVAL_PENDING"
                  );
                  break;
                case "not_domain_allowed":
                  this.errorLoginMessage = this.translate.instant(
                    "USER.LOGIN.NOT_DOMAIN_ALLOWED"
                  );
                  break;
                case "not_verified_user":
                  this.errorLoginMessage = this.translate.instant(
                    "USER.LOGIN.NOT_VERIFIED_USER"
                  );
                  break;
              }
            }
          }
        });
      }
    );
    this.roleSubscription = this.roleService.$roles.subscribe((roles) => {
      this.roles = roles;
    });
    this.appsByUser = this.appUser.$appsUser.subscribe((apps) => {
      console.log("apps by user arrived!");
      console.log(apps);
      if (apps.length == 1) {
        let role = this.auth.getRoleCode();
        if (role === "USER" && apps.length == 1) {
          this.app.get(apps[0].idApp);
        } else {
          this.route.navigateByUrl("/");
        }
      } else {
        this.route.navigateByUrl("/");
      }
    });
    this.goToApp$ = this.app.$app.subscribe(async (app) => {
      let appToken = await this.appTokenService.generateToken(
        app.id,
        this.user.id
      );
      
      let appLink = this.app.getAuthUrl(app, appToken.id);
      console.log(appLink);
      window.location.href = appLink;
    });
  }

  ngOnInit(): void {
    this.setForm();
    this.startSubscriptions();
    this.roleService.getAll();
    let msg = this.cookie.get("login-msg");
    if (msg.length > 0) {
      this.cookie.delete("login-msg");
    }
  }

  setForm() {
    this.frm = this.fb.group({
      email: ["", [Validators.required, Validators.email]],
      password: ["", Validators.required],
    });
  }

  async setLogedUSer(data: any) {
    if (data) {
      {
        const tokenData = this.auth.decodeToken(data["token"]);
        if (typeof tokenData["data"] === "string") {
          this.user = JSON.parse(tokenData["data"]) as IUser;
        } else this.user = tokenData["data"] as IUser;
        let userRole = this.roles.find((r) => r.id === this.user.role);
        this.user["token_refresh"] = data["refreshToken"];
        this.auth.setJwsTokenCookie(data["token"]);
        this.auth.setRoleCookie(userRole.code, this.user.id);
        this.userService.user$.next(this.user);
        if (userRole.code === "USER") {
          console.log("get apps by user");
          this.appUser.getByIdUser(this.user.id);
        } else {
          this.route.navigateByUrl("/");
        }
      }
    }
  }

  tryLogin() {
    this.disable = true;
    var data = {
      username: this.frm.get("email").value,
      password: this.frm.get("password").value,
    };
    this.auth.tryLogin(data.username, data.password).subscribe({
      next: (data) => this.setLogedUSer(data),
      error: (e) =>
        this.popup.addMessage(
          "Error",
          this.translate.instant("USER.BAD_LOGIN")
        ),
    });
  }

  logout() {
    //this.userService.logout();
    this.auth.logOut();
    this.userService.user$.next(undefined);
    this.route.navigate(["/login"]);
  }
}
