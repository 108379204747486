<div fxLayout="column" class="role-dash" >
  <div fxLayout="row" fxLayoutAlign="start start" class="button-role">
    <button mat-raised-button color="primary" (click)="newRole()">
      New Role
    </button>
  </div>
  <div fxLayout="row" fxLayoutAlign="start start" fxFlex="100">
    <app-role-list fxFlex="50"></app-role-list>
    <app-role-form fxFlex="30" class="edit-role mat-elevation-z8"> </app-role-form>
  </div>
</div>
