/*
 * File: user.model.ts                                                         *
 * Project: @nousmedis/guard-front                                             *
 * File Created: Friday, 25th November 2022 13:19:33                           *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Friday, 10th March 2023 14:55:00                             *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2023 Nousmedis, CeGe                                       *
 */

import { CookieService } from "ngx-cookie-service";
import { ApiService } from "../../api/services/api.service";
import { IUser } from "../interfaces/user.interface";
import { UserRegister } from "../interfaces/user.register";
import { environment } from "src/environment";

export class UserModel {
  private userCookie = "NMGUARD-JWT";
  private user: IUser;

  url_api = {
    login: "users/login",
    register: "users/register",
    update: "users/",
    exists: "users/exists/",
    list: "users/list",
    approve: "users/approve",
  };

  constructor(private api: ApiService, private cookie: CookieService) {}

  public register(user: UserRegister) {
    return this.api.post(this.url_api.register, user);
  }

  public update(user: IUser) {
    return this.api.patch(this.url_api.update + user.id, user);
  }

  public exists(username: string) {
    return this.api.get(this.url_api.exists + username);
  }

  public approve(username: string, approvedBy: string) {
    let body = {
      username: username,
      approvedBy: approvedBy,
    };
    return this.api.post(this.url_api.approve, body);
  }

  public disapprove(user: IUser) {
    let body = {
      approvedBy: "",
      approved: false,
    };
    return this.api.patch(this.url_api.update + user.id, body);
  }

  public block(user: IUser) {
    let body = {
      blocked: true,
    };
    return this.api.patch(this.url_api.update + user.id, body);
  }

  public unblock(user: IUser) {
    let body = {
      blocked: false,
    };
    return this.api.patch(this.url_api.update + user.id, body);
  }

  public getUsers() {
    return this.api.get(this.url_api.list);
  }

  public getUserById(userId: string) {
    return this.api.get("users/" + userId);
  }

  public reset() {
    this.user = undefined;
    this.cookie.set(
      this.userCookie,
      JSON.stringify(this.user),
      0,
      "/",
      environment.base_domain,
      true
    );
    //this.logoOut.next(true);
    localStorage.clear();
  }

  public logout() {
    this.cookie.delete(this.userCookie, "/", environment.base_domain);
    return Promise.resolve(undefined);
    //this.reset();
  }
}
