import { Component, OnDestroy, OnInit } from "@angular/core";
import { RoleForm } from "../../forms/role.form";
import { Subscription } from "rxjs";
import { RoleService } from "../../services/role.service";
import { FormBuilder } from "@angular/forms";
import { FormGeneratorService } from "src/app/modules/form-generator/services/form-generator.service";
import { PopInfoService } from "src/app/modules/pop-info/services/pop-info-service.service";
import { ActivatedRoute, Router } from "@angular/router";
import { FormCreator } from "src/app/modules/form-generator/helpers/form-creator";

@Component({
  selector: "app-role-form",
  templateUrl: "./role-form.component.html",
  styleUrls: ["./role-form.component.scss"],
})
export class RoleFormComponent implements OnInit,OnDestroy {
  dataLoaded: boolean = false;
  roleForm: any = JSON.parse(JSON.stringify(RoleForm)); //Deep copy without reference Important!
  loadRole: Subscription;
  fc: FormCreator;

  constructor(
    private fb: FormBuilder,
    private fgService: FormGeneratorService,
    private roleService: RoleService,
    private popup: PopInfoService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.fc = new FormCreator(fb);
  }
  ngOnDestroy(): void {
    this.loadRole.unsubscribe();
  }

  startSubscriptions() {
    this.loadRole = this.roleService.$role.subscribe((role) => {
      this.dataLoaded = false;
      this.roleForm = this.fc.fillFormSchemaWithData(
        JSON.parse(JSON.stringify(RoleForm)),
        role
      );
      this.dataLoaded = true;
    });

    this.fgService.form$.subscribe((form) => {
      const data = form.roleform;
      if (data.id) {
        this.roleService.update(data.id, data);
        this.popup.addMessage("Info", "Role updated successfully");
      } else {
        this.roleService.create(data);
        this.popup.addMessage("Info", "Role created successfully");
      }
    });
  }
  ngOnInit(): void {
    this.startSubscriptions();
    this.roleService.init();
  }
}
