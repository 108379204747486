import { Component, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { Subscription } from "rxjs";
import { FormCreator } from "src/app/modules/form-generator/helpers/form-creator";
import { FormGeneratorService } from "src/app/modules/form-generator/services/form-generator.service";
import { OrganitzationService } from "../../services/organitzation.service";
import { PopInfoService } from "src/app/modules/pop-info/services/pop-info-service.service";
import { ActivatedRoute, Router } from "@angular/router";
import { OrganitzationForm } from "../../forms/organitzation.form";

@Component({
  selector: "app-organitzation-form",
  templateUrl: "./organitzation-form.component.html",
  styleUrls: ["./organitzation-form.component.scss"],
})
export class OrganitzationFormComponent implements OnInit {
  dataLoaded: boolean = false;
  orgForm: any = OrganitzationForm; //Deep copy without reference Important!
  loadOrg: Subscription;
  fc: FormCreator;

  constructor(
    private fb: FormBuilder,
    private fgService: FormGeneratorService,
    private orgService: OrganitzationService,
    private popup: PopInfoService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.fc = new FormCreator(fb);
  }

  ngOnDestroy(): void {
    this.loadOrg.unsubscribe();
  }

  startSubscriptions() {
    this.loadOrg = this.orgService.$organitzation.subscribe((org) => {
      this.dataLoaded = false;
      this.orgForm = this.fc.fillFormSchemaWithData(OrganitzationForm, org);
      this.dataLoaded = true;
    });

    this.fgService.form$.subscribe((form) => {
      const data = form.organitzationform;
      if (data.id) {
        this.orgService.update(data.id, data);
        this.popup.addMessage("Info", "Organitzation updated successfully");
      } else {
        this.orgService.create(data);
        this.popup.addMessage("Info", "Organitzation created successfully");
      }
    });
  }

  ngOnInit(): void {
    this.startSubscriptions();
    this.orgService.init();
  }
}
