<ng-container *ngIf="formIsLoaded">
<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <ng-container *ngFor="let group of jsonForm.groups">
    <div formGroupName="{{ group.groupName }}">
      <h3 *ngIf="group.showLabel">{{ group.groupLabel }}</h3>
      <ng-container *ngFor=
      "let field of group.fields">
        <app-field-generator
          [field]="field"
          [formGroup]="getFormGroup(group.groupName)"
        ></app-field-generator>
      </ng-container>
    </div>
  </ng-container>
  <button type="submit" color="primary" mat-raised-button>Submit</button>
</form>
</ng-container>
