/*
 * File: form-creator.ts                                                       *
 * Project: @nousmedis/syncwall-frontend                                       *
 * File Created: Thursday, 17th February 2022 11:48:25                         *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Tuesday, 7th March 2023 17:08:27                             *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */

//Helper importante!!!

/**
 * Recivbe un objeto de tipo FormBuilder y otro de tipo
 * FormSchema (Marca de la casa) y convierte el objeto en un formulario.
 * Pendiente
 *  - Generación de validaciones
 */

import { Injectable } from "@angular/core";
import {
  Form,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { FieldInterface } from "../interfaces/field.interface";
import { FormSchema } from "../interfaces/form-schema.interface";
// import { FindValueOperator } from 'rxjs';

@Injectable()
export class FormCreator {
  private groupNames: string[] = [];
  private fb: FormBuilder;
  private formGroups = new Object();
  public form: FormGroup = new FormGroup([]);

  constructor(fb: FormBuilder) {
    this.fb = fb;
  }

  private setControls(fg: FormGroup, controls: FieldInterface[]) {
    for (let c = 0; c < controls.length; c++) {
      let fc = new FormControl();
      if (controls[c].value) {
        fc.setValue(controls[c].value);
      }
      if (controls[c].required) fc.setValidators(Validators.required);
      if (controls[c].validators){
        controls[c].validators.forEach((val) => {
          fc.setValidators(val);
        });
      }
        
      fg.addControl(controls[c].key, fc);
    }
    return fg;
  }

  private setGroups(formSchema: FormSchema) {
    let formGroups: FormGroup[] = [];
    for (let g = 0; g < formSchema.groups.length; g++) {
      let schemaFields = formSchema.groups[g].fields;
      let group = new FormGroup({});
      group = this.setControls(group, schemaFields);
      formGroups.push(group);
    }
    return formGroups;
  }

  generateAngularForm(schema: FormSchema): FormGroup {
    let groupNames = schema.groups.map((g) => {
      return g.groupName;
    });
    let form: FormGroup = new FormGroup({});
    let groups = this.setGroups(schema);
    for (let n = 0; n < groupNames.length; n++) {
      form.addControl(groupNames[n], groups[n]);
    }

    return form;
  }

  fillFormSchemaWithData(schema:FormSchema,data:any){
    let groups = schema.groups;
      for (let g = 0; g < groups.length; g++) {
        for (let f = 0; f < schema.groups[g].fields.length; f++) {
          schema.groups[g].fields[f].value =
            data[schema.groups[g].fields[f].key];
        }
      }
      return schema;
  }
}
