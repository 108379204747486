/*
 * File: list.component.ts                                                     *
 * Project: @nousmedis/guard-front                                             *
 * File Created: Thursday, 3rd March 2022 15:43:37                             *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Friday, 10th March 2023 14:54:46                             *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */

import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { TranslateService } from "@ngx-translate/core";
import { CookieService } from "ngx-cookie-service";
import { Observer, Subscription } from "rxjs";
import { ApiService } from "src/app/modules/api/services/api.service";
import { PopInfoService } from "src/app/modules/pop-info/services/pop-info-service.service";
import { UserList } from "src/app/modules/user/interfaces/user-list.interface";
import { ModalUserForms } from "../../helpers/modal-user-forms.helper";
import { AuthModel } from "../../models/auth.model";
import { UserModel } from "../../models/user.model";
import { UserService } from "../../services/user.service";
import {tmsTable } from "src/app/modules/table-generator/interfaces/table.interface";
import { tmsFields } from "src/app/modules/table-generator/enums/table-field.enum";

@Component({
  selector: "app-user-list",
  templateUrl: "./user-list.component.html",
  styleUrls: ["./user-list.component.scss"],
})
export class UserListComponent implements OnInit, OnDestroy {
  displayedColumns = ["name", "surname", "user", "role", "blocked", "actions"];

  usersDataSource: MatTableDataSource<UserList> =
    new MatTableDataSource<UserList>();
  loading = true;

  changes: Subscription;
  userModalForms: ModalUserForms;
  userModel: UserModel;
  authModel: AuthModel;

  tableUser: tmsTable = {
    name: "user-list",
    columns: [],
    data: [],
  };

  constructor(
    private userService: UserService,
    private api: ApiService,
    dialog: MatDialog,
    translate: TranslateService,
    private popup: PopInfoService,
    cookie: CookieService
  ) {
    this.userModalForms = new ModalUserForms(
      dialog,
      translate,
      popup,
      api,
      cookie,
      userService
    );
    this.authModel = new AuthModel(api, cookie);
    this.userModel = new UserModel(api, cookie);
  }
  ngOnDestroy(): void {
    this.stopListen();
  }

  ngOnInit(): void {
    this.listenChanges();
    this.setTable();
  }

  setTable() {
    this.loading = true;
    this.userModel.getUsers().subscribe((result) => {
      let users: UserList[] = result;
      this.usersDataSource.data = users;
      this.loading = false;
      this.tableUser;
      this.tableUser = {
        name: "user-list",
        data: users,
        columns: [
          {
            name: "id",
            label: "Id",
            type: tmsFields.text,
            visible: false,
            options: {},
          },
          {
            name: "name",
            label: "Nombre",
            type: tmsFields.text,
            visible: true,
            options: {},
          },
          {
            name: "surname",
            label: "Apellidos",
            type: tmsFields.text,
            visible: true,
            options: {},
          },
          {
            name: "blocked",
            label: "Bloqueado",
            type: tmsFields.boolean_button,
            visible: true,
            options: {
              action: (row: any) => {
                if (!row.blocked) {
                  this.block(row);
                } else {
                  this.unblock(row);
                }
              },
            },
          },
          {
            name: "approved",
            label: "Aprobado",
            type: tmsFields.boolean_button,
            visible: true,
            options: {
              action: (row: any) => {
                if (!row.approved) {
                  this.approve(row);
                } else {
                  this.disapprove(row);
                }
              },
            },
          },
          {
            name: "role",
            label: "Rol",
            type: tmsFields.text,
            visible: true,
            options: {},
          },
          {
            name: "actions",
            label: "Acciones",
            type: tmsFields.actions,
            visible: true,
            options: {
              actions: [
                {
                  name: "edit",
                  label: "Edit",
                  icon: "edit",
                  color: "primary",
                  visible: (row) => {
                    return true;
                  },
                  action: (row) => {
                    this.userService.getUserById(row.id);
                  },
                },
                {
                  name: "reset",
                  label: "Resetear",
                  icon: "lock_reset",
                  color: "primary",
                  visible: (row) => {
                    return row.approved ? true : false;
                  },
                  action: (row) => {
                    this.reset(row.username);
                  },
                },
                {
                  name: "welcome",
                  label: "Enviar correo de bienvenida",
                  icon: "mail",
                  color: "primary",
                  visible: (row) => {
                    return row.approved ? true : false;
                  },
                  action: (row) => {
                    if (row.approved) {
                      this.welcome(row.username);
                    }
                  },
                }
              ],
            },
          },
        ],
      };
    });
  }

  listenChanges() {
    this.changes = this.userService.userChanged.subscribe((data) => {
      this.setTable();
    });
  }

  stopListen() {
    this.changes.unsubscribe();
  }

  reset(mail: string) {
    this.api.get("users/recover/" + mail).subscribe((data) => {
      console.log(data);
      this.popup.addMessage("Info", "Mail sended", "info");
    });
  }

  welcome(mail: string) {
    this.api.get("users/welcome/" + mail).subscribe((data) => {
      this.popup.addMessage("Info", "Mail sended", "info");

      console.log(data);
    });
    console.log(mail);
  }

  createUser() {
    this.userService.init();
  }

  async approve(userToApprove: any) {
    let user = await this.authModel.getCurrentUser();
    this.userModel.approve(userToApprove.id, user.id).subscribe((data) => {
      this.popup.addMessage("Info", "User approved", "info");
      this.setTable();
    });
  }

  async disapprove(userToDisApprove: any) {
    this.userModel.disapprove(userToDisApprove).subscribe((data) => {
      this.popup.addMessage("Info", "User disapproved", "info");
      this.setTable();
    });
  }

  async block(userToBlock: any) {
    this.userModel.block(userToBlock).subscribe((data) => {
      this.popup.addMessage("Info", "User blocked", "info");
      this.setTable();
    });
  }

  async unblock(userToBlock: any) {
    this.userModel.unblock(userToBlock).subscribe((data) => {
      this.popup.addMessage("Info", "User unblocked", "info");
      this.setTable();
    });
  }
}
