<!--
 * File: hidden.component.html                                                 *
 * Project: @nousmedis/syncwall-frontend                                       *
 * File Created: Tuesday, 14th February 2023 15:38:01                          *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Tuesday, 14th February 2023 18:25:24                         *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2023 Nousmedis, CeGe                                       *
 -->


<div [formGroup]="fg">
<input
type="hidden"
id="{{ key }}"
value="{{ value }}"
/>
</div>