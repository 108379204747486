/*
 * File: edit.component.ts                                                     *
 * Project: @nousmedis/syncwall-frontend                                       *
 * File Created: Tuesday, 15th March 2022 11:52:45                             *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Tuesday, 21st February 2023 12:50:41                         *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */

import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { FormCreator } from "src/app/modules/form-generator/helpers/form-creator";
import { IUser } from "src/app/modules/user/interfaces/user.interface";
import { userEditSchema } from "../../schemas/user-edit.schema";
import { UserService } from "../../services/user.service";
import { Subscription } from "rxjs";
import { FormGeneratorService } from "src/app/modules/form-generator/services/form-generator.service";
import { userRegisterSchema } from "../../schemas/user-register.schema";
import { RoleService } from "src/app/modules/nm-apps/services/role.service";
import { Role } from "src/app/modules/nm-apps/interfaces/role.interface";
import { FormSchema } from "src/app/modules/form-generator/interfaces/form-schema.interface";

@Component({
  selector: "app-user-edit",
  templateUrl: "./user-edit.component.html",
  styleUrls: ["./user-edit.component.scss"],
})
export class UserEditComponent implements OnInit {
  @Input() user: IUser;
  formDataIsLoaded: boolean;
  form!: FormGroup;
  fbMain!: FormGroup;
  show: boolean = false;
  orderedMainFields = JSON.parse(JSON.stringify(userEditSchema));
  fc: FormCreator;
  userSubs: Subscription;
  formSubs: Subscription;
  roleSub: Subscription;
  roles: any[];
  constructor(
    private fb: FormBuilder,
    private fgService: FormGeneratorService,
    private userService: UserService,
    private roleService: RoleService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.startSubscriptions();
    this.roleService.getAll();
    this.fc = new FormCreator(this.fb);
    this.show = false;
    //this.initForm();
  }

  addKeyValueToField(schema: FormSchema, fieldKey: string, keyValue: any) {
    for (let g = 0; g < schema.groups.length; g++) {
      let field = schema.groups[g].fields.filter((field) => {
        return field.key == fieldKey;
      })[0];
      if (field) field.data.base = keyValue;
    }
  }

  startSubscriptions() {
    this.roleSub = this.roleService.$roles.subscribe((roles) => {
      this.roles = roles.map((r: Role) => {
        return { label: r.name, value: r.id };
      });
    });

    this.userSubs = this.userService.user$.subscribe((user) => {
      this.user = user;
      if (user.id) {
        this.orderedMainFields = this.fc.fillFormSchemaWithData(
          userEditSchema,
          user
        );
      } else {
        this.orderedMainFields = this.fc.fillFormSchemaWithData(
          userRegisterSchema,
          user
        );
      }
      this.addKeyValueToField(this.orderedMainFields, "role", this.roles);

      this.show = true;
    });

    this.formSubs = this.fgService.form$.subscribe((form) => {
      if (form.user_new) {
        let data = form.user_new;

        this.userService.register(data);
      } else if (form.user) {
        let data = form.user;
        if (data.id) {
          this.userService.update(data);
        }
      }
    });
  }

  public findInvalidControls() {
    const invalid = [];

    return invalid;
  }
}
