<mat-list>

  <div *ngFor="let item of menuItems">
    <a
      mat-list-item
      [routerLink]="item.link"
      *ngIf="item.roles.includes(userRole)"
      routerLinkActive="item-active"
    >
      <mat-icon
        *ngIf="item.icon"
        class="icon-menu"
        [ngClass]="{ 'only-icon': !expanded }"
        >{{ item.icon }}</mat-icon
      >

      <span *ngIf="expanded" class="text-menu">{{
        item.name | translate
      }}</span></a
    >
    </div >
</mat-list>
