import { Injectable } from "@angular/core";
import { Observable, Observer, Subject } from "rxjs";
import { ApiService } from "../../api/services/api.service";
import { Role } from "../interfaces/role.interface";
@Injectable({
  providedIn: "root",
})
export class RoleService {
  endPoints = {
    getAll: "roles/",
    getByUser: "roles/user/",
    getByCode: "roles/code/",
    get: "roles/search/",
    update: "roles/",
    create: "roles/",
    delete: "roles/",
  };

  loading: Subject<boolean> = new Subject<boolean>();
  $roles: Subject<Role[]> = new Subject<Role[]>();
  $role: Subject<Role> = new Subject<Role>();

  constructor(private api: ApiService) {}
  init() {
    let role: Role = {
      id: "",
      name: "",
      code: "",
      createdBy: "",
    };
    this.$role.next(role);
  }
  getAll(): void {
    this.loading.next(true);
    this.api.get(this.endPoints.getAll).subscribe((data) => {
      this.loading.next(false);
      this.$roles.next(data);
    });
  }

  getById(id: string): void {
    this.loading.next(true);
    this.api.get(this.endPoints.get + id).subscribe((data) => {
      this.loading.next(false);
      this.$role.next(data);
    });
  }

  //Get user role
  getByUserId(id: string): void {
    this.loading.next(true);
    this.api.get(this.endPoints.getByUser + id).subscribe((data) => {
      this.loading.next(false);
      this.$role.next(data);
    });
  }

  //Get role by code
  getByCode(code: string): void {
    this.loading.next(true);
    this.api.get(this.endPoints.getByCode + code).subscribe((data) => {
      this.loading.next(false);
      this.$role.next(data);
    });
  }

  update(id: string, role: Role) {
    this.loading.next(true);
    this.api.patch(this.endPoints.update + id, role).subscribe((data) => {
      this.loading.next(false);
      this.getAll();
      this.$role.next(data);
    });
  }

  create(appUser: any): void {
    this.loading.next(true);

    this.api.post(this.endPoints.create, appUser).subscribe((data) => {
      this.loading.next(false);
      this.getAll();
      this.$role.next(data);
    });
  }
  delete(id: string): void {
    this.loading.next(true);

    this.api.delete(this.endPoints.delete + id).subscribe((data) => {
      this.loading.next(false);
      this.getAll();
    });
  }
}
