import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { AppUserForm } from "../../forms/app-user.form";
import { FormCreator } from "src/app/modules/form-generator/helpers/form-creator";
import { Subscription } from "rxjs";
import { FormGeneratorService } from "src/app/modules/form-generator/services/form-generator.service";
import { AppUserService } from "../../services/app-user.service";
import { PopInfoService } from "src/app/modules/pop-info/services/pop-info-service.service";
import { AppUser } from "../../interfaces/app-user.interface";
import { UserService } from "src/app/modules/user/services/user.service";
import { RoleService } from "../../services/role.service";
import { UserModel } from "src/app/modules/user/models/user.model";
import { ApiService } from "src/app/modules/api/services/api.service";
import { CookieService } from "ngx-cookie-service";
import { FormSchema } from "src/app/modules/form-generator/interfaces/form-schema.interface";
import { Role } from "../../interfaces/role.interface";
import { IUser } from "src/app/modules/user/interfaces/user.interface";
import { AppsService } from "../../services/apps.service";

@Component({
  selector: "app-app-user-form",
  templateUrl: "./app-user-form.component.html",
  styleUrls: ["./app-user-form.component.scss"],
})
export class AppUserFormComponent implements OnInit, OnDestroy {
  form: FormGroup;
  appUserForm: any = AppUserForm;
  fc: FormCreator;
  serviceSub: Subscription;
  roleSub: Subscription;
  dataLoaded: boolean = false;
  isNew: boolean = true;
  idApp: string = "";
  users: any[] = [];
  roles: any[] = [];
  userModel: UserModel;

  constructor(
    private fb: FormBuilder,
    private fgService: FormGeneratorService,
    private appUserService: AppUserService,
    private popup: PopInfoService,
    private roleService: RoleService,
    private appService: AppsService,
    apiServcice: ApiService,
    cookiService: CookieService
  ) {
    this.fc = new FormCreator(fb);
    this.userModel = new UserModel(apiServcice, cookiService);
  }

  ngOnDestroy(): void {
    this.serviceSub.unsubscribe();
    this.roleSub.unsubscribe();
  }

  addKeyValueToField(schema: FormSchema, fieldKey: string, keyValue: any) {
    for (let g = 0; g < schema.groups.length; g++) {
      let field = schema.groups[g].fields.filter((field) => {
        return field.key == fieldKey;
      })[0];
      if (field) field.data.base = keyValue;
    }
  }

  startObservables() {
    this.roleSub = this.roleService.$roles.subscribe((roles) => {
      this.roles = roles.map((r: Role) => {
        return { label: r.name, value: r.id };
      });
    });

    this.serviceSub = this.appUserService.$appUser.subscribe(
      (appUser: AppUser) => {
        this.dataLoaded = false;
        this.appUserForm = this.fc.fillFormSchemaWithData(AppUserForm, appUser);
        this.addKeyValueToField(this.appUserForm, "idRole", this.roles);
        this.addKeyValueToField(this.appUserForm, "idUser", this.users);
        let id = this.appUserForm.groups[0].fields.filter((field) => {
          return field.key == "id";
        })[0].value;
        this.idApp = id;
        this.isNew = id ? false : true;
        this.dataLoaded = true;
        console.log("Cambio de appUser")
      }
    );

    this.fgService.form$.subscribe((form) => {
      if (form.apptouser) {
        const data = form.apptouser;
        if (data.id) {
          this.appUserService.update(data.id, data);
          this.popup.addMessage("Info", "Permission updated", "success");
        } else {
          this.appUserService.create(data);
          this.popup.addMessage("Info", "Permission created", "success");
        }
      }
    });
  }

  async loadExtraData() {
    this.userModel.getUsers().subscribe((data) => {
      this.users = data.map((r: IUser) => {
        return {
          label: r.name + " " + r.surname + "(" + r.username + ")",
          value: r.id,
        };
      });
    });
    this.roleService.getAll();
  }

  ngOnInit(): void {
    this.loadExtraData();
    this.startObservables();
    this.appService.reloadAppUserForm.subscribe((data) => {
      this.dataLoaded = false;
    });
    
  }
}
