/*
 * File: modal-user-forms.helper.ts                                            *
 * Project: @nousmedis/syncwall-frontend                                       *
 * File Created: Friday, 25th November 2022 13:19:33                           *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Tuesday, 21st February 2023 13:01:23                         *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2023 Nousmedis, CeGe                                       *
 */



import { MatDialog } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import jwt_decode from "jwt-decode";
import { CookieService } from "ngx-cookie-service";
import { ApiService } from "../../api/services/api.service";
import { PopInfoService } from "../../pop-info/services/pop-info-service.service";
import { ModalDialogComponent } from "../../tms-tools/components/modal-dialog/modal-dialog.component";
import { UserEditComponent } from "../components/user-edit/user-edit.component";
import { UserModel } from "../models/user.model";
import { UserService } from "../services/user.service";

export class ModalUserForms {
    userModel: UserModel;
    constructor(
        private modalDialog: MatDialog,
        private translate: TranslateService,
        private popup: PopInfoService,
        api: ApiService,
        cookie: CookieService,
        private userService: UserService
    ) {
        this.userModel = new UserModel(api, cookie);
    }

    public registerForm() {
        this.modalDialog
            .open(ModalDialogComponent, {
                width: "954px",
                height: "auto",
                closeOnNavigation: false,
                disableClose: true,
                panelClass: "mat-elevation-z8",
                data: {
                    title: this.translate.instant("USER.ADD"),
                    component: UserEditComponent,
                    type: "form",
                },
            })
            .afterClosed()
            .subscribe((createUserForm) => {
                let data = createUserForm["mainFields"];
                this.userModel.register(data).subscribe((result) => {
                    this.popup.addMessage(
                        "info",
                        this.translate.instant("USER.ADDED")
                    );
                    this.userService.userChanged.next(true);
                });
            });
    }

    public editForm(user: any) {
        this.modalDialog
            .open(ModalDialogComponent, {
                width: "954px",
                height: "auto",
                closeOnNavigation: false,
                disableClose: true,
                panelClass: "mat-elevation-z8",
                data: {
                    title: this.translate.instant("USER.EDIT"),
                    component: UserEditComponent,
                    type: "form",
                    user: user,
                },
            })
            .afterClosed()
            .subscribe((createUserForm) => {
                let data = createUserForm["user"];
                console.log(data)
                this.userModel.update(data).subscribe((result) => {
                    this.popup.addMessage(
                        "info",
                        this.translate.instant("USER.MODIFIED")
                    );
                    this.userService.userChanged.next(true);
                    console.log(result)
                    //this.userModel.setDataUser(result, true);
                    //this.userModel.user = data;
                    this.translate.setDefaultLang(result.lang);
                });
            });
    }
}
