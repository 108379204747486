<!--
 * File: select.component.html                                                 *
 * Project: @nousmedis/syncwall-frontend                                       *
 * File Created: Tuesday, 14th February 2023 15:37:31                          *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Tuesday, 21st February 2023 12:01:48                         *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2023 Nousmedis, CeGe                                       *
 -->



<mat-form-field
        appearance="outline"
        class="full-witdth"
        [formGroup]="formGroup"
    >
        <mat-label>{{ label | translate }}</mat-label>

        <mat-select formControlName="{{ key }}" [(value)]="selectedValue">
            <mat-option
                *ngFor="let item of selectValues"
                [value]="item.value"
                >{{ item.label | translate }}</mat-option
            >
        </mat-select>
    </mat-form-field>