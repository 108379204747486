export const environment = {
  production: false,
  base_url: "https://dev.api.apps.nousmedis.com/api/v1/",
  base_socket: "https://dev.api.apps.nousmedis.com/",
  mode: "prod",
  pop_duration: 10,
  base_protocol: "https",
  base_domain: ".dev.apps.nousmedis.com",
  base_port: "443",
  app_cookie:"dev-app-guard"
};