import { Component, OnDestroy, OnInit } from "@angular/core";
import { OrganitzationService } from "../../services/organitzation.service";
import { Subscription } from "rxjs";
import { tmsTable } from "src/app/modules/table-generator/interfaces/table.interface";
import { Organitzation } from "../../interfaces/organitzation";
import { tmsFields } from "src/app/modules/table-generator/enums/table-field.enum";

@Component({
  selector: "app-organitzation-list",
  templateUrl: "./organitzation-list.component.html",
  styleUrls: ["./organitzation-list.component.scss"],
})
export class OrganitzationListComponent implements OnInit, OnDestroy {
  serviceSub: Subscription;
  tableData: tmsTable = {
    name: "Orgs Table",
    columns: [],
    data: [],
  };
  loadingDataTable: boolean = true;

  constructor(private orgService: OrganitzationService) {}

  ngOnDestroy(): void {
    this.stopObservables();
  }


  startObservables() {
    this.serviceSub = this.orgService.$organitzations.subscribe((orgs: Organitzation[]) => {
      this.setTableData(orgs);
    });
  }

  setTableData(data: Organitzation[]) {
    this.tableData = {
      name: "Orgs Table",
      columns: [
        {
          name: "id",
          type: tmsFields.text,
          label: "Id",
          visible: true,
          options: {},
        },
        {
          name: "name",
          type: tmsFields.text,
          label: "Name",
          visible: true,
          options: {},
        },
        {
          name: "name",
          type: tmsFields.text,
          label: "Name",
          visible: true,
          options: {},
        },
        {
          name: "FrntendUrl",
          type: tmsFields.text,
          label: "FrontEnd",
          visible: true,
          options: {},
        },
        {
          name: "backendUrl",
          type: tmsFields.text,
          label: "Api",
          visible: true,
          options: {},
        },
        {
          name: "default",
          type: tmsFields.boolean_button,
          label: "Default",
          visible: true,
          options: {
            action: (row) => {
              this.orgService.setDefault(row);
            }
          },
        },
        {
          name: "actions",
          type: "actions",
          label: "Actions",
          visible: true,
          options: {
            actions: [
              {
                name: "edit",
                label: "Edit",
                icon: "edit",
                color: "primary",
                visible: (row) => {
                  return true;
                },
                action: (row) => {
                  this.orgService.getById(row.id);
                },
              },
              {
                name: "delete",
                label: "Delete",
                icon: "delete",
                color: "warn",
                visible: (row) => {
                  return true;
                },
                action: (row) => {
                  this.orgService.delete(row.id);
                  this.loadData();
                },
              },
            ],
          },
        },
      ],
      data: data,
    };
    this.loadingDataTable = false;
  }

  stopObservables() {
    this.serviceSub.unsubscribe();
  }

  loadData() {
    this.orgService.getAll();
  }

  ngOnInit(): void {
    this.startObservables();
    this.loadData();
  }
}
