/*
 * File: user-register.schema.ts                                               *
 * Project: core                                                      *
 * File Created: Thursday, 3rd March 2022 17:37:21                             *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Tuesday, 14th June 2022 17:10:13                             *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */

import { EmailValidator, Validators } from "@angular/forms";
import { FormSchema } from "src/app/modules/form-generator/interfaces/form-schema.interface";
import { PasswordStrengthValidator } from "../../form-generator/validators/password-strength.validator";
import { mailValidator } from "../../form-generator/validators/email.validator";

//Estaria bien que esto fuera ina interface
export const userRegisterSchema: FormSchema = {
  groups: [
    {
      groupName: "user_new",
      groupLabel: "New User",
      showLabel: false,
      fields: [
        {
          key: "id",
          type: "hidden",
          value: "false",
          label: "",
          required: true,
          order: 1,
          data: null,
        },
        {
          key: "blocked",
          type: "hidden",
          value: "false",
          label: "",
          required: true,
          order: 1,
          data: null,
        },
        {
          key: "username",
          type: "text",
          value: "",
          label: "USER.EMAIL",
          required: true,
          order: 3,
          data: null,
          validators: [mailValidator],
        },
        {
          key: "password",
          type: "password",
          value: "",
          label: "USER.PASSWORD",
          required: true,
          order: 4,
          data: null,
          validators: [PasswordStrengthValidator],
        },
        {
          key: "password2",
          type: "password",
          value: "",
          label: "USER.REPEAT_PASSWORD",
          required: true,
          order: 4,
          data: null,
        },
        {
          key: "name",
          type: "text",
          value: "",
          label: "NAME",
          required: true,
          order: 1,
          data: null,
        },
        {
          key: "surname",
          type: "text",
          value: "",
          label: "USER.SURNAME",
          required: true,
          order: 2,
          data: null,
        },
        {
          key: "role",
          type: "select",
          value: "",
          label: "ROLE",
          required: false,
          order: 5,
          data: {
            
            selected: "User",
          },
        },
      ],
    },
  ],
};
