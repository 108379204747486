/*
 * File: available-lang.ts                                                     *
 * Project: catalog-cloud                                                      *
 * File Created: Tuesday, 15th March 2022 17:03:03                             *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Friday, 15th July 2022 11:46:59                              *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */



export const langs = [
  {
    "lang":"Castellano",
    "value":"es_ES"
  },
  {
    "lang":"English",
    "value":"en_US"
  }
]
