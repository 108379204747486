import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { BrowserModule } from "@angular/platform-browser";
import { FormGeneratorModule } from "../form-generator/form-generator.module";
import { MatMenuModule } from "@angular/material/menu";
import { MatIconModule } from "@angular/material/icon";
import { RouterModule } from "@angular/router";
import { MatDividerModule } from "@angular/material/divider";
import { AppListComponent } from "./components/app-list/app-list.component";
import { MatCardModule } from "@angular/material/card";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatTableModule } from "@angular/material/table";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatSelectModule } from "@angular/material/select";
import { MatOptionModule } from "@angular/material/core";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatChipsModule } from "@angular/material/chips";
import { MatTabsModule } from "@angular/material/tabs";

import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { FormComponent } from "./components/app-form/app-form.component";
import { HttpClientModule } from "@angular/common/http";
import { AppUserFormComponent } from "./components/app-user-form/app-user-form.component";
import { AppUserListComponent } from "./components/app-user-list/app-user-list.component";
import { RoleFormComponent } from "./components/role-form/role-form.component";
import { RoleListComponent } from "./components/role-list/role-list.component";
import { TmsToolsModule } from "../tms-tools/tms-tools.module";
import { RolesComponent } from "./components/roles/roles.component";
import { AppsComponent } from "./components/apps/apps.component";
import { AppUserComponent } from "./components/app-user/app-user.component";
import { TableGeneratorModule } from "../table-generator/table-generator.module";
import { OrganitzationsComponent } from "./components/organitzations/organitzations.component";
import { OrganitzationListComponent } from "./components/organitzation-list/organitzation-list.component";
import { OrganitzationFormComponent } from "./components/organitzation-form/organitzation-form.component";
import { OrganitzationSelectorComponent } from './components/organitzation-selector/organitzation-selector.component';

@NgModule({
  declarations: [
    FormComponent,
    AppListComponent,
    AppUserFormComponent,
    AppUserListComponent,
    RoleFormComponent,
    RoleListComponent,
    RolesComponent,
    AppsComponent,
    AppUserComponent,
    OrganitzationsComponent,
    OrganitzationListComponent,
    OrganitzationFormComponent,
    OrganitzationSelectorComponent,
  ],
  exports: [OrganitzationSelectorComponent],
  imports: [
    HttpClientModule,
    CommonModule,
    MatAutocompleteModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    MatTabsModule,
    MatDialogModule,
    MatSelectModule,
    MatCheckboxModule,
    MatOptionModule,
    MatButtonToggleModule,
    MatChipsModule,
    MatIconModule,
    MatSlideToggleModule,
    FormGeneratorModule,
    RouterModule,
    FlexLayoutModule,
    TmsToolsModule,
    TableGeneratorModule,
  ],
})
export class nmApsModule {}
