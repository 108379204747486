/*
 * File: hidden.component.ts                                                   *
 * Project: @nousmedis/syncwall-frontend                                       *
 * File Created: Tuesday, 14th February 2023 15:38:01                          *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Tuesday, 14th February 2023 18:25:34                         *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2023 Nousmedis, CeGe                                       *
 */



import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-hidden',
  templateUrl: './hidden.component.html',
  styleUrls: ['./hidden.component.scss']
})
export class HiddenComponent implements OnInit {
  @Input() fcName:string;
  @Input() key:string;
  @Input() fg:FormGroup;
  @Input() value:any;
  constructor() { }

  ngOnInit(): void {
  }

}
