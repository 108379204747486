import { FormSchema } from "../../form-generator/interfaces/form-schema.interface";
import { noSpaceValidator } from "../../form-generator/validators/no-space.validator";

export const RoleForm: FormSchema = {
  groups: [
    {
      groupName: "roleform",
      groupLabel: "Role",
      showLabel: true,
      fields: [
        {
          type: "hidden",
          label: "ID",
          value: "",
          key: "id",
          required: true,
          order: 1,
        },
        {
          type: "text",
          label: "Code",
          value: "",
          key: "code",
          required: true,
          order: 2,
          validators: [noSpaceValidator],
        },
        {
          type: "text",
          label: "Name",
          value: "",
          key: "name",
          required: true,
          order: 3,
        },
      ],
    },
  ],
};
