/*
 * File: input.component.ts                                                    *
 * Project: @nousmedis/syncwall-frontend                                       *
 * File Created: Tuesday, 14th February 2023 15:37:52                          *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Tuesday, 14th February 2023 16:15:48                         *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2023 Nousmedis, CeGe                                       *
 */



import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})
export class InputComponent implements OnInit {
  @Input() formGroup:FormGroup
  @Input() appearance:string = "outline";
  @Input() fcName:string;
  @Input() key:string;
  @Input() class:string = "full-width"
  @Input() label:string;
  @Input() autocomplete:string ="off";
  @Input() autocapitalize:string = "off";
  @Input() spellCheck:string ="false";
  @Input() classError:string = "field-error"
  @Input() errorIcon:string = " error"
  @Input() value:any;
  constructor() { }

  ngOnInit(): void {
  }

}
