/*
 * File: login-activate.interceptor.ts                                         *
 * Project: @nousmedis/guard-front                                             *
 * File Created: Monday, 17th January 2022 17:58:27                            *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Friday, 10th March 2023 11:37:55                             *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */

import { Injectable } from "@angular/core";
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot,
} from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { Observable } from "rxjs";
import { ApiService } from "../../api/services/api.service";
import { IUser } from "../interfaces/user.interface";
import { AuthModel } from "../models/auth.model";
import { UserService } from "../services/user.service";

@Injectable({
    providedIn: "root",
})
export class LoginActivate implements CanActivate {
    auth: AuthModel;
    user: IUser | undefined;
    constructor(
        private router: Router,
        api: ApiService,
        cookie: CookieService
    ) {
        this.auth = new AuthModel(api, cookie);
        
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        this.user = this.auth.getCurrentUser();
        if (!this.user) {
            this.router.navigate(["/login"]);
        }

        return true;
    }
}
