<mat-form-field appearance="fill" fxFill>
  <mat-label>{{ label }}</mat-label>
  <mat-chip-list #chipList aria-label="label">
    <mat-chip *ngFor="let chip of chips"
    [removable]="true"
    >
      <!-- <button mat-icon-button (click)="removeCategory(category.id)"> -->
      {{ chip.value }}
      <button class="chip-remove" (click)="returnRemove(chip)">
        <mat-icon class="chip-remove-icon">cancel</mat-icon>
      </button>

    </mat-chip>
    <input
      type="text"
      matInput
      [matAutocomplete]="auto"
      [formControlName]="name"
      [matChipInputFor]="chipList"
    />
  </mat-chip-list>
  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
    <mat-option
      *ngFor="let option of filteredOptions | async"
      [value]="option"
      (click)="returnSelected(option)"
    >
      {{ option.value }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
