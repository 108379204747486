/*
 * File: email.validator.ts                                                    *
 * Project: catalog-cloud                                                      *
 * File Created: Friday, 4th March 2022 10:13:12                               *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Tuesday, 15th March 2022 11:35:24                            *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */

import {
  AbstractControl,
  ValidationErrors,
  Validator,
  ValidatorFn,
} from "@angular/forms";

export const mailValidator: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  let pattern = new RegExp("^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$");

  let match = pattern.test(control.value);
  if (match) return null;
  else {
    let error: ValidationErrors = { message: "USER.INVALID_MAIL" };
    return error;
  }
};
