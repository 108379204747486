import {
  AfterContentInit,
  AfterViewChecked,
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { Observable, Observer, Subscription, timeout } from "rxjs";
import { App } from "../../interfaces/app.interface";
import { AppsService } from "../../services/apps.service";
import { TranslateService } from "@ngx-translate/core";
import { Router } from "@angular/router";
import { tmsTable } from "src/app/modules/table-generator/interfaces/table.interface";
import { tmsTableColumn } from "src/app/modules/table-generator/interfaces/table-column.interface";

@Component({
  selector: "app-app-list",
  templateUrl: "./app-list.component.html",
  styleUrls: ["./app-list.component.scss"],
})
export class AppListComponent implements OnInit, OnDestroy {
  serviceSub: Subscription;

  tableData: tmsTable = {
    name: "Apps Table",
    columns: [],
    data: [],
  };
  loadingDataTable: boolean = true;

  constructor(
    private appService: AppsService,
    private translate: TranslateService,
    private router: Router
  ) {}

  ngOnDestroy(): void {
    this.stopObservables();
  }

  startObservables() {
    this.serviceSub = this.appService.$apps.subscribe((apps: App[]) => {
      this.setTableData(apps);
    });
  }

  setTableData(data: App[]) {
    this.tableData = {
      name: "Apps Table",
      columns: [
        {
          name: "id",
          type: "text",
          label: "Id",
          visible: true,
          options: {},
        },
        {
          name: "idApp",
          type: "text",
          label: "Id App",
          visible: true,
          options: {},
        },
        {
          name: "name",
          type: "text",
          label: "Name",
          visible: true,
          options: {},
        },
        {
          name: "active",
          type: "boolean",
          label: "Active",
          visible: true,
          options: {},
        },
        {
          name: "createdAt",
          type: "datetime",
          label: "Created At",
          visible: false,
          options: {},
        },
        {
          name: "updatedAt",
          type: "datetime",
          label: "Updated At",
          visible: true,
          options: {},
        },
        {
          name: "actions",
          type: "actions",
          label: "Actions",
          visible: true,
          options: {
            actions: [
              {
                name: "edit",
                label: "Edit",
                icon: "edit",
                color: "primary",
                visible: (row) => {
                  return true;
                },
                action: (row) => {
                  this.appService.get(row.id);
                },
              },
              {
                name: "delete",
                label: "Delete",
                icon: "delete",
                color: "warn",
                visible: (row) => {
                  return true;
                },
                action: (row) => {
                  this.appService.remove(row.id);
                  this.loadData();
                },
              },
            ],
          },
        },
      ],
      data: data,
    };
    this.loadingDataTable = false;
  }

  stopObservables() {
    this.serviceSub.unsubscribe();
  }

  loadData() {
    this.appService.getAll();
  }

  ngOnInit(): void {
    this.startObservables();
    this.loadData();
  }
}
