import { Component, OnInit } from "@angular/core";
import { App } from "../nm-apps/interfaces/app.interface";
import { UserService } from "../user/services/user.service";
import { ApiService } from "../api/services/api.service";
import { CookieService } from "ngx-cookie-service";
import { AppsService } from "../nm-apps/services/apps.service";
import { UserModel } from "../user/models/user.model";
import { IUser } from "../user/interfaces/user.interface";
import { AuthModel } from "../user/models/auth.model";
import { Subscription, firstValueFrom } from "rxjs";
import { AppTokenService } from "../nm-apps/services/app-token.servivce";

@Component({
  selector: "app-landing-page",
  templateUrl: "./landing-page.component.html",
  styleUrls: ["./landing-page.component.scss"],
})
export class LandingPageComponent implements OnInit {
  userModel: UserModel;
  user: IUser;
  auth: AuthModel;
  appsSubscription: Subscription;
  blockedSubscription: Subscription;
  approvedSubscription: Subscription;
  myApps: App[];
  myAppsLoaded: boolean = false;
  loading: boolean = true;
  pendingUsers: IUser[] = [];
  blockedUsers: IUser[] = [];
  roleCode: string;

  constructor(
    private userService: UserService,
    private apiService: ApiService,
    cookieService: CookieService,
    private appService: AppsService,
    private appTokenService: AppTokenService,
  ) {
    this.auth = new AuthModel(apiService, cookieService);
    this.userModel = new UserModel(apiService, cookieService);
    this.user = this.auth.getCurrentUser();
    this.roleCode = this.auth.getRoleCode();
   
  }

  ngOnInit(): void {
    this.startObservers();
    this.loadMyApps();
  }

  startObservers() {
    this.appsSubscription = this.appService.$apps.subscribe((apps: App[]) => {
      this.myApps = apps;
      this.getAuthUrl();
    });
    this.blockedSubscription = this.userService
      .getBlockeds()
      .subscribe((users: IUser[]) => {
        this.blockedUsers = users;
      });

    this.approvedSubscription = this.userService
      .getPendingApproval()
      .subscribe((users: IUser[]) => {
        this.pendingUsers = users;
      });
  }

  async getStats(url: string) {
    try {
      let stats = await firstValueFrom(this.apiService.getFullUrl(url));
      return stats;
    } catch (err) {
      return null;
    }
  }

  async getAuthUrl() {
    for (let a = 0; a < this.myApps.length; a++) {
      try {
        let appToken = await this.appTokenService.generateToken(
          this.myApps[a].id,
          this.user.id
        );
        this.myApps[a].appLink = this.appService.getAuthUrl(
          this.myApps[a],
          appToken.id
        );
        this.myApps[a].stats = await this.getStats(this.myApps[a].statsUrl);

      } catch (ex: any) {
        console.error(ex);
      }
    }
    this.myAppsLoaded = true;
    this.loading = false;
  }

  loadMyApps() {
    this.appService.getByIdUser(this.user.id);
  }
}
