<div  class="orgs-container">
    <div fxLayout="row" fxLayoutAlign="start start" class="orgs-buttons">
      <button mat-raised-button color="primary" (click)="newOrg()">
        New Organitzation
      </button>
    </div>
    <div fxLayout="row" fxLayoutAlign="start start" fxFlex="100">
      <app-organitzation-list fxFlex="50"></app-organitzation-list>
      <app-organitzation-form fxFlex="40" style="margin-left: 4em;"> </app-organitzation-form>
    </div>
  </div>
  