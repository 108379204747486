/*
 * File: app.module.ts                                                         *
 * Project: @nousmedis/guard-front                                             *
 * File Created: Friday, 25th November 2022 13:19:33                           *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Thursday, 9th March 2023 15:58:07                            *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2023 Nousmedis, CeGe                                       *
 */

import { Injectable, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";

import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
  SocialLoginModule,
  SocialAuthServiceConfig,
} from "@abacritt/angularx-social-login";
import {
  GoogleLoginProvider,
  FacebookLoginProvider,
} from "@abacritt/angularx-social-login";

// import { ProaproComponent } from "./clients/proapro/proapro.component";
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
  HttpBackend,
} from "@angular/common/http";

import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatListModule } from "@angular/material/list";
import { MatButtonModule } from "@angular/material/button";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";

import { CookieService } from "ngx-cookie-service";
import { RouterModule } from "@angular/router";
import { UserModule } from "./modules/user/user.module";
import { MenusModule } from "./modules/menus/menus.module";
import { TmsToolsModule } from "./modules/tms-tools/tms-tools.module";
import { AuthInterceptor } from "./modules/user/interceptors/auth-interceptor";
import { AppRoutingModule } from "./app-routing.module";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatDialogModule } from "@angular/material/dialog";
import { CommonModule } from "@angular/common";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatCardModule } from "@angular/material/card";
import { MatSelectModule } from "@angular/material/select";
import { MatOptionModule } from "@angular/material/core";
import { HttpErrorInterceptor } from "./modules/api/interceptors/http-error.interceptor";
import { nmApsModule } from "./modules/nm-apps/nm-apps.module";
import { LandingPageComponent } from "./modules/landing-page/landing-page.component";
import { MatIconModule } from "@angular/material/icon";
import { OrganitzationSelectorComponent } from "./modules/nm-apps/components/organitzation-selector/organitzation-selector.component";

// Factory function required during AOT compilation
export function httpTranslateLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@Injectable({ providedIn: "root" })
export class HttpClientTrans extends HttpClient {
  constructor(handler: HttpBackend) {
    super(handler);
  }
}

export function HttpLoaderFactory(httpClient: HttpClientTrans) {
  return new TranslateHttpLoader(httpClient, "/assets/i18n/", ".json");
}
@NgModule({
  declarations: [AppComponent, LandingPageComponent],
  imports: [
    HttpClientModule,
    CommonModule,
    BrowserModule,
    RouterModule,
    MatSidenavModule,
    MatSlideToggleModule,
    BrowserAnimationsModule,
    MatIconModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClientTrans],
      },
    }),

    AppRoutingModule,
    MatSnackBarModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatCardModule,
    MatSelectModule,
    MatOptionModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    UserModule,
    MenusModule,
    nmApsModule,
  ],
  exports: [TranslateModule, OrganitzationSelectorComponent],

  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    CookieService,
    {
      provide: "SocialAuthServiceConfig",
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              "13388866202-puh9d5jupc4ujo090gk72teseufo42bq.apps.googleusercontent.com"
            ),
          },
        ],
        onError: (err) => {
          console.log(err);
        },
      } as SocialAuthServiceConfig,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
