/*
 * File: form-generator.module.ts                                              *
 * Project: @nousmedis/syncwall-frontend                                       *
 * File Created: Friday, 25th November 2022 13:19:33                           *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Tuesday, 14th February 2023 15:39:08                         *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2023 Nousmedis, CeGe                                       *
 */



import { CommonModule } from "@angular/common";
import {
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
    NO_ERRORS_SCHEMA,
} from "@angular/core";
import { CcAutocompleteComponent } from "./components/cc-autocomplete/cc-autocomplete.component";
import { FieldGeneratorComponent } from "./components/field-generator/field-generator.component";
import { FormCreator } from "./helpers/form-creator";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { TranslateModule } from "@ngx-translate/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatSelectModule } from "@angular/material/select";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatOptionModule } from "@angular/material/core";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import {MatChipsModule} from '@angular/material/chips';
import { MatIconModule } from "@angular/material/icon";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { SelectComponent } from './components/select/select.component';
import { InputComponent } from './components/input/input.component';
import { HiddenComponent } from './components/hidden/hidden.component';
import { PasswordComponent } from './components/password/password.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { SlideComponent } from './components/slide/slide.component';
import { DynamicComponent } from "./components/dynamic/dynamic.component";
import { GenerateFormComponent } from './components/generate-form/generate-form.component';
import { FlexLayoutModule } from "@angular/flex-layout";

@NgModule({
    imports: [
        CommonModule,
        MatAutocompleteModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserModule,
        MatInputModule,
        MatFormFieldModule,
        MatButtonModule,
        MatDialogModule,
        MatSelectModule,
        MatCheckboxModule,
        MatOptionModule,
        MatButtonToggleModule,
        MatChipsModule,
        MatIconModule,
        MatSlideToggleModule,
        FlexLayoutModule
    ],
    declarations: [CcAutocompleteComponent, FieldGeneratorComponent,  SelectComponent,DynamicComponent, InputComponent, HiddenComponent, PasswordComponent, CheckboxComponent, SlideComponent, GenerateFormComponent],
    exports: [CcAutocompleteComponent, FieldGeneratorComponent, TranslateModule,GenerateFormComponent],
    providers: [FormCreator]
})
export class FormGeneratorModule {}
