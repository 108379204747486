import { Injectable } from "@angular/core";
import { Observable, Observer, Subject } from "rxjs";
import { ApiService } from "../../api/services/api.service";
import { Role } from "../interfaces/role.interface";
import { Organitzation } from "../interfaces/organitzation";
@Injectable({
  providedIn: "root",
})
export class OrganitzationService {
  endPoints = {
    getAll: "organitzation/",
    get: "organitzation/search/",
    create: "organitzation/create/",
    update: "organitzation/update/",
    delete: "organitzation/delete/",
  };

  loading: Subject<boolean> = new Subject<boolean>();
  $organitzations: Subject<Organitzation[]> = new Subject<Organitzation[]>();
  $organitzation: Subject<Organitzation> = new Subject<Organitzation>();

  constructor(private api: ApiService) {}
  init() {
    let org: Organitzation = {
      id: "",
      name: "",
      backendUrl: "",
      frontendUrl: "",
      updatedBy: "",
      createdBy: "",
      default: false
    };
    this.$organitzation.next(org);
  }
  getAll(): void {
    this.loading.next(true);
    this.api.get(this.endPoints.getAll).subscribe((data) => {
      this.loading.next(false);
      this.$organitzations.next(data);
    });
  }
  getById(id: string): void {
    this.loading.next(true);
    this.api.get(this.endPoints.get + id).subscribe((data) => {
      this.loading.next(false);
      this.$organitzation.next(data);
    });
  }

  update(id: string, org: Organitzation) {
    this.loading.next(true);
    this.api.patch(this.endPoints.update + id, org).subscribe((data) => {
      this.loading.next(false);
      this.getAll();
      this.$organitzation.next(data);
    });
  }

  create(org: any): void {
    this.loading.next(true);

    this.api.post(this.endPoints.create, org).subscribe((data) => {
      this.loading.next(false);
      this.getAll();
      this.$organitzation.next(data);
    });
  }

  setDefault(org:Organitzation)
  {
    org.default = org.default ? false : true;
    this.update(org.id, org);
    
  }
  delete(id: string): void {
    this.loading.next(true);

    this.api.delete(this.endPoints.delete + id).subscribe((data) => {
      this.loading.next(false);
      this.getAll();
    });
  }
}
