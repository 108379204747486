import { FormSchema } from "../../form-generator/interfaces/form-schema.interface";
import { noSpaceValidator } from "../../form-generator/validators/no-space.validator";

export const OrganitzationForm: FormSchema = {
  groups: [
    {
      groupName: "organitzationform",
      groupLabel: "Orgranitzation",
      showLabel: true,
      fields: [
        {
          type: "hidden",
          label: "ID",
          value: "",
          key: "id",
          required: true,
          order: 1,
        },
        {
          type: "text",
          label: "Name",
          value: "",
          key: "name",
          required: true,
          order: 2,
          validators: [],
        },
        {
          type: "text",
          label: "FrontEnd Url",
          value: "",
          key: "frontendUrl",
          required: true,
          order: 3,
          validators: [],
        },
        {
          type: "text",
          label: "Api base url",
          value: "",
          key: "backendUrl",
          required: true,
          order: 4,
          validators: [],
        },
        {
          type: "checkbox",
          label: "Default Organitzation",
          value: false,
          key: "default",
          required: true,
          order: 5,
          validators: [],

        }
      ],
    },
  ],
};
