import { Injectable } from "@angular/core";
import { Observable, Observer, Subject } from "rxjs";
import { environment } from "src/environment";
import { ApiService } from "../../api/services/api.service";
import { App } from "../interfaces/app.interface";

@Injectable({
  providedIn: "root",
})
export class AppsService {
  enpoints = {
    get: "apps/",
    getall: "apps/all",
    getByIdUser:"apps/user/",
    update: "apps/",
    remove: "apps/",
    create: "apps/",
  };

  loading: Subject<boolean> = new Subject<boolean>();

  $apps: Subject<App[]> = new Subject<App[]>();
  $app: Subject<App> = new Subject<App>();

  reloadAppUserForm:Subject<boolean> = new Subject<boolean>();

  constructor(private api: ApiService) {}

  init() {
    const app: App = {
      id:"",
      idApp: "",
      name: "",
      authUrl: "",
      statsUrl: "",
      active: false,
      createdBy: ""
    };
    this.$app.next(app);
  }

  async getAll() {
    this.loading.next(true);
    this.api.get(this.enpoints.getall).subscribe((data) => {
      this.$apps.next(data);
      this.loading.next(false);
    });
  }

  async getByIdUser(id:string) {
    this.loading.next(true);
    console.log(id)
    if (id.length > 0){
      this.api.get(this.enpoints.getByIdUser+id).subscribe((data) => {
        this.$apps.next(data);
        this.loading.next(false);
      });
    }
    
  }

  async get(id: string) {
    this.loading.next(true);
    this.api.get(this.enpoints.get + id).subscribe((app) => {
      this.loading.next(false);
      this.$app.next(app);
    });
  }

  async update(id: string, app: App) {
    this.loading.next(true);
    this.api.patch(this.enpoints.update + id, { app }).subscribe((app) => {
      this.loading.next(false);
      this.getAll();
    });
  }

  async remove(id: string) {
    this.loading.next(true);
    this.api.delete(this.enpoints.remove + "/" + id).subscribe((apps) => {
      this.loading.next(false);
      this.getAll();
    });
  }

  async create(app: App) {
    try {
      this.loading.next(true);
      this.api.post(this.enpoints.create, { app }).subscribe((app) => {
        this.loading.next(false);
        this.getAll();
      });
    } catch (error) {
      console.log(error);
    }
  }

  getAuthUrl(app:App,idAppToken:string) {
    return app.authUrl+"?appToken="+idAppToken;
  }

  async getStatsUrl(app:App) {

  }
}
