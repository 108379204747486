/*
 * File: menu.component.ts                                                     *
 * Project: @nousmedis/syncwall-frontend                                       *
 * File Created: Wednesday, 22nd December 2021 12:48:17                        *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Friday, 24th February 2023 13:21:59                          *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */

import { Component, Input, OnInit } from "@angular/core";
import { IUser } from "src/app/modules/user/interfaces/user.interface";
import { Router } from "@angular/router";
import { UserService } from "../../services/user.service";
import { MatDialog } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { UserModel } from "../../models/user.model";
import { ApiService } from "src/app/modules/api/services/api.service";
import { CookieService } from "ngx-cookie-service";
import { ModalUserForms } from "../../helpers/modal-user-forms.helper";
import { PopInfoService } from "src/app/modules/pop-info/services/pop-info-service.service";
import { AuthModel } from "../../models/auth.model";

@Component({
  selector: "user-options-side-menu",
  templateUrl: "./user-options-side-menu.component.html",
  styleUrls: ["./user-options-side-menu.component.scss"],
})
export class UserOptionsSideMenuComponent implements OnInit {
  @Input() user: IUser;
  userModel: UserModel;
  userModalForms: ModalUserForms;
  auth: AuthModel;
  roleCode: string;
  constructor(
    dialog: MatDialog,
    traslate: TranslateService,
    api: ApiService,
    cookie: CookieService,
    private router: Router,
    popup: PopInfoService,
    private userService: UserService
  ) {
    this.userModalForms = new ModalUserForms(
      dialog,
      traslate,
      popup,
      api,
      cookie,
      userService
    );
    this.userModel = new UserModel(api, cookie);
    this.auth = new AuthModel(api, cookie);
    this.roleCode = this.auth.getRoleCode();
  }

  ngOnInit(): void {
  
  }

  logout() {
    this.userModel.logout().then((data: any) => {
      this.userService.user$.next(undefined);
      this.router.navigate(["/login"]);
    });
  }

  editMe() {
    this.userModalForms.editForm(this.auth.getCurrentUser());
  }
}
