import { Component, Input, OnInit } from "@angular/core";
import { AppUserService } from "../../services/app-user.service";
import { AppsService } from "../../services/apps.service";

@Component({
  selector: "app-app-user",
  templateUrl: "./app-user.component.html",
  styleUrls: ["./app-user.component.scss"],
})
export class AppUserComponent implements OnInit {
  @Input() idApp: string;
  constructor(
    private appUserService: AppUserService,
    private appService: AppsService
  ) {}

  ngOnInit(): void {
    this.appService.reloadAppUserForm.subscribe((data) => {
      this.appUserService.init(this.idApp);
    });
  }

  initAppUser() {
    this.appUserService.init(this.idApp);
  }
}
