/*
 * File: actionsMenu.service.ts                                                *
 * Project: catalog-cloud                                                      *
 * File Created: Thursday, 17th February 2022 11:48:25                         *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Friday, 17th June 2022 10:54:48                              *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */



import { EventEmitter, Injectable, Output } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SideMenuService {
  constructor() {}

  private expanded:boolean = false;
  
  isExpanded:Subject<boolean> = new Subject<boolean>();


  public toggle(): void {
    this.expanded = !this.expanded;
    this.isExpanded.next(this.expanded);
  }
}
