import { Component, OnInit } from "@angular/core";
import { Observer, Subscription } from "rxjs";
import { Role } from "../../interfaces/role.interface";
import { tmsTable } from "src/app/modules/table-generator/interfaces/table.interface";
import { RoleService } from "../../services/role.service";
import { TranslateService } from "@ngx-translate/core";
import { Router } from "@angular/router";
import { PopInfoService } from "src/app/modules/pop-info/services/pop-info-service.service";

@Component({
  selector: "app-role-list",
  templateUrl: "./role-list.component.html",
  styleUrls: ["./role-list.component.scss"],
})
export class RoleListComponent implements OnInit {
  $appData: Observer<Role[] | undefined>;
  data: Role[];
  serviceSub: Subscription;

  tableData: tmsTable = {
    name: "Roles Table",
    columns: [],
    data: [],
  };
  loadingDataTable: boolean = true;

  constructor(
    private roleService: RoleService,
    private popup: PopInfoService,
    private translate: TranslateService,
    private router: Router

  ) {}

  ngOnDestroy(): void {
    this.stopObservables();
  }

  stopObservables() {
    this.serviceSub.unsubscribe();
  }

  startObservables() {
    this.serviceSub = this.roleService.$roles.subscribe((roles: Role[]) => {
      console.log("No llego aca", roles);
      this.loadingDataTable = true;
      this.setTableData(roles);
    });
  }

  setTableData(roles: Role[]) {
    this.tableData = {
      name: "Roles Table",
      columns: [
        {
          name: "id",
          type: "text",
          label: "Id",
          visible: false,
          options: {},
        },
        {
          name: "name",
          type: "text",
          label: "Name",
          visible: true,
          options: {},
        },
        {
          name: "createdAt",
          type: "datetime",
          label: "Created At",
          visible: false,
          options: {},
        },
        {
          name: "updatedAt",
          type: "datetime",
          label: "Updated At",
          visible: true,
          options: {},
        },
        {
          name: "actions",
          type: "actions",
          label: "Actions",
          visible: true,
          options: {
            actions: [
              {
                name: "edit",
                label: "Edit",
                icon: "edit",
                color: "primary",
                visible: (row) => {
                  return true;
                },
                action: (row) => {
                  this.roleService.getById(row.id);
                },
              },
              {
                name: "delete",
                label: "Delete",
                icon: "delete",
                color: "warn",
                visible: (row) => {
                  return true;
                },
                action: (row) => {
                  this.removeRole(row);
                 

                },
              },
            ],
          },
        },
      ],
      data: roles,
    };

    this.loadingDataTable = false;
  }

  removeRole(row){
    this.roleService.delete(row.id);
    this.popup.addMessage("Info", "Role deleted");
  }
  loadData() {
    this.roleService.getAll();
  }

  ngOnInit(): void {
    this.startObservables();
    this.loadData();
  }
}
