/*
 * File: email.validator.ts                                                    *
 * Project: catalog-cloud                                                      *
 * File Created: Friday, 4th March 2022 10:13:12                               *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Tuesday, 15th March 2022 11:35:24                            *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */

import {
  AbstractControl,
  ValidationErrors,
  Validator,
  ValidatorFn,
} from '@angular/forms';

export const noSpaceValidator: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  let pattern = new RegExp('/^\S*$/');

  let match = pattern.test(control.value);
  if (match)   control.setErrors(null);
  else
  {
    control.setErrors({message:"FORM.VALIDATOR.NO_SPACE"});
  }
  return null;

};
