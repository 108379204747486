/*
 * File: user.module.ts                                                        *
 * Project: @nousmedis/guard-front                                             *
 * File Created: Thursday, 9th March 2023 11:12:44                             *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Friday, 10th March 2023 14:06:36                             *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2023 Nousmedis, CeGe                                       *
 */

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { UserOptionsSideMenuComponent } from "./components/user-options-side-menu/user-options-side-menu.component";
import { BrowserModule } from "@angular/platform-browser";
import { FormGeneratorModule } from "../form-generator/form-generator.module";
import { MatMenuModule } from "@angular/material/menu";
import { MatIconModule } from "@angular/material/icon";
import { RouterModule } from "@angular/router";
import { MatDividerModule } from "@angular/material/divider";
import { UserEditComponent } from "./components/user-edit/user-edit.component";
import { UserListComponent } from "./components/user-list/user-list.component";
import { UserLoginComponent } from "./components/user-login/user-login.component";
import { UserResetPasswordComponent } from "./components/user-reset-password/user-reset-password.component";
import { MatCardModule } from "@angular/material/card";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatTableModule } from "@angular/material/table";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import {
  SocialLoginModule,
  SocialAuthServiceConfig,
  GoogleLoginProvider,
} from "@abacritt/angularx-social-login";
import { googleProvider } from "./providers/google";
import { TmsToolsModule } from "../tms-tools/tms-tools.module";
import { TableGeneratorModule } from "../table-generator/table-generator.module";
import { UserListPendingComponent } from './components/user-list-pending/user-list-pending.component';

@NgModule({
  declarations: [
    UserOptionsSideMenuComponent,
    UserEditComponent,
    UserListComponent,
    UserLoginComponent,
    UserResetPasswordComponent,
    UserListPendingComponent,
  ],
  imports: [
    SocialLoginModule,
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    FormGeneratorModule,
    MatMenuModule,
    MatIconModule,
    RouterModule,
    MatDividerModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatInputModule,
    MatDialogModule,
    MatCheckboxModule,
    MatTableModule,
    FlexLayoutModule,
    MatButtonModule,
    TmsToolsModule,
    TableGeneratorModule,
  ],
  exports: [UserOptionsSideMenuComponent],
  providers: [
    {
      provide: "SocialAuthServiceConfig",
      useValue: googleProvider,
    },
  ],
})
export class UserModule {}
