/*
 * File: auth-interceptor.ts                                                   *
 * Project: @nousmedis/syncwall-frontend                                       *
 * File Created: Wednesday, 22nd December 2021 12:48:17                        *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Monday, 20th February 2023 21:01:06                          *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */

import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CookieService } from "ngx-cookie-service";
import { Observable } from "rxjs";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    jwtCookie: string = "NMGUARD-JWT";

    constructor(private cookieService: CookieService) {}
    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        let cookie = this.cookieService.get(this.jwtCookie);
        if (cookie.length == 0) {
            return next.handle(req);
        }
        cookie = JSON.parse(cookie);
        const token = cookie['token'];
        const exp = new Date(cookie['exp'] * 1000);
        const now = new Date();
        let expired = false;
        if (now.getTime() > exp.getTime()) {
            expired = true;
            
        }
        if ((token) && (!expired)) {
            const headers = req.clone({
                headers: req.headers.set("api-key", `Bearer ${token}`),
            });
            return next.handle(headers);
        } else {
            return next.handle(req);
        }
    }
}
