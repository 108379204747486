import { Component, OnInit } from '@angular/core';
import { RoleService } from '../../services/role.service';

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss']
})
export class RolesComponent implements OnInit {

  constructor(private roleService:RoleService) { }

  ngOnInit(): void {
  }
  newRole(){
    this.roleService.init();
  }

}
