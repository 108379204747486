/*
 * File: user.service.ts                                                       *
 * Project: core                                                      *
 * File Created: Wednesday, 22nd December 2021 12:48:17                        *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Friday, 15th July 2022 13:30:13                              *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */

import { Injectable } from "@angular/core";

import { Subject } from "rxjs";
import { IUser } from "../interfaces/user.interface";
import { ApiService } from "../../api/services/api.service";
import { CookieService } from "ngx-cookie-service";
import { UserRegister } from "../interfaces/user.register";
import { RoleService } from "../../nm-apps/services/role.service";

@Injectable({
  providedIn: "root",
})
export class UserService {
  userChanged: Subject<boolean> = new Subject<boolean>();
  logoOut: Subject<boolean> = new Subject<boolean>();
  user$: Subject<IUser> = new Subject<IUser>();
  users$: Subject<IUser[]> = new Subject<IUser[]>();
  user: IUser;
  url_api = {
    login: "users/login",
    register: "users/register",
    update: "users/",
    exists: "users/exists/",
    list: "users/list",
    approve: "users/approve",
    pendingApproval: "users/pending",
    blockeds: "users/blocked",
  };

  constructor(
    private api: ApiService,
    private cookie: CookieService
  ) {
  }

  init() {
    let user: IUser = {
      id: "",
      _id: "",
      externalId: "",
      provider: "",
      photo: "",
      username: "",
      password: "",
      name: "",
      surname: "",
      blocked: true,
      role: "",
      lang: "es",
      approved: false,
    };
    this.user$.next(user);
  }
  public register(user: UserRegister) {
    this.api.post(this.url_api.register, user).subscribe((data) => {
      console.log("Usuario creado", data);
      this.user$.next(data);
      this.userChanged.next(true);
    });
  }

  public update(user: IUser) {
    this.api.patch(this.url_api.update + user.id, user).subscribe((data) => {
      console.log("Usuario actualizado", data);
      this.user$.next(data);
      this.userChanged.next(true);
    });
  }

  public exists(username: string) {
    return this.api.get(this.url_api.exists + username);
  }

  public approve(username: string, approvedBy: string) {
    let body = {
      username: username,
      approvedBy: approvedBy,
    };
    this.api.post(this.url_api.approve, body).subscribe((data) => {
      this.userChanged.next(true);
    });
  }

  public getUsers() {
    this.api.get(this.url_api.list).subscribe((data) => {
      this.users$.next(data);
      this.userChanged.next(true);
    });
  }

  public getUserById(userId: string) {
    this.api.get("users/" + userId).subscribe((data) => {
      this.user$.next(data);
      this.userChanged.next(true);
    });
  }

  public getPendingApproval() {
    return this.api.get(this.url_api.pendingApproval);
  }

  public getBlockeds() {
    return this.api.get(this.url_api.blockeds);
  }
}
