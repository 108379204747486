import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { FormSchema } from "../../interfaces/form-schema.interface";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from "@angular/forms";
import { group } from "@angular/animations";
import { FieldInterface } from "../../interfaces/field.interface";
import { FormGeneratorService } from "../../services/form-generator.service";
import { FormCreator } from "../../helpers/form-creator";

@Component({
  selector: "app-generate-form",
  templateUrl: "./generate-form.component.html",
  styleUrls: ["./generate-form.component.scss"],
})

/**
 * This component impletements the OnChanges interface instead OnInit
 * because this will allow us to detect when the @Input changes.
 *
 * This component is responsible for generating the form based on the schema
 * provided by the user.
 * @param jsonForm The schema of the form to be generated
 * @param fb The form builder service
 *
 */
export class GenerateFormComponent implements OnChanges, OnInit {
  // The schema of the form to be generated
  @Input() jsonForm: FormSchema;
  form: FormGroup;
  formGroups: any = [];
  // The group names that can be found on jsonForm
  groupNames: string[] = [];
  loading: boolean = true;
  fc: FormCreator;
  formIsLoaded: boolean = false;
  constructor(
    private fb: FormBuilder,
    private fgService: FormGeneratorService
  ) {
    this.fc = new FormCreator(fb);
  }
  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    this.formIsLoaded = false;

    this.jsonForm = changes.jsonForm.currentValue;
    this.groupNames = this.jsonForm.groups.map((group) => group.groupName);
    this.form = this.fc.generateAngularForm(this.jsonForm);
    this.formIsLoaded = true;
  }

  getFormGroup(groupName: string) {
    return this.form.get(groupName) as FormGroup;
  }

  onSubmit() {
    this.fgService.setForm(this.form.value);
  }
}
