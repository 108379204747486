import { Component, Input, OnInit, SimpleChanges } from "@angular/core";
import { Subscription } from "rxjs";
import { tmsTable } from "src/app/modules/table-generator/interfaces/table.interface";
import { AppUserService } from "../../services/app-user.service";
import { AppUser } from "../../interfaces/app-user.interface";
import { UserModel } from "src/app/modules/user/models/user.model";
import { RoleService } from "../../services/role.service";
import { ApiService } from "src/app/modules/api/services/api.service";
import { CookieService } from "ngx-cookie-service";
import { IUser } from "src/app/modules/user/interfaces/user.interface";
import { Role } from "../../interfaces/role.interface";

@Component({
  selector: "app-app-user-list",
  templateUrl: "./app-user-list.component.html",
  styleUrls: ["./app-user-list.component.scss"],
})
export class AppUserListComponent implements OnInit {
  @Input() idApp: string;
  loadingDataTable: boolean = false;
  tableData: tmsTable = {
    name: "app-user-list",
    columns: [],
    data: [],
  };
  dataSub: Subscription;
  dataUpdatedSub: Subscription;
  roleSub: Subscription;
  userModel: UserModel;
  users: any[] = [];
  roles: any[] = [];

  constructor(
    private appUserService: AppUserService,
    private roleService: RoleService,
    apiServcice: ApiService,
    cookiService: CookieService
  ) {
    this.userModel = new UserModel(apiServcice, cookiService);
  }

  ngOnDestroy(): void {
    this.stopObservables();
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    this.loadData();
  }

  startObservables() {
    this.dataUpdatedSub = this.appUserService.$dataUpdated.subscribe(
      (updated: boolean) => {
        this.loadData();
      }
    );

    this.dataSub = this.appUserService.$appsUsers.subscribe(
      (apps: AppUser[]) => {
        this.setTableData(apps);
      }
    );

    this.roleSub = this.roleService.$roles.subscribe((roles: Role[]) => {
      this.roles = roles.map((r: Role) => {
        return { label: r.name, value: r.id };
      });
    });
  }

  setTableData(data: AppUser[]) {
    this.tableData = {
      name: "Apps Table",
      columns: [
        {
          name: "id",
          type: "text",
          label: "Id",
          visible: false,
          options: {},
        },
        {
          name: "idApp",
          type: "text",
          label: "Id App",
          visible: false,
          options: {},
        },
        {
          name: "idUser",
          type: "text",
          label: "User",
          visible: true,
          options: {
            convert: (row) => {
              let name = this.users.filter((u) => {
                return u.value == row.idUser;
              })[0];
              if (name)   return name.label;
              else return "";
            },
          },
        },
        {
          name: "idRole",
          type: "text",
          label: "Role",
          visible: true,
          options: {
            convert: (row) => {
              let role = this.roles.filter((u) => {
                return u.value == row.idRole;
              })[0];
              return role.label;
            },
          },
        },
        {
          name: "createdAt",
          type: "datetime",
          label: "Created At",
          visible: false,
          options: {},
        },
        {
          name: "updatedAt",
          type: "datetime",
          label: "Updated At",
          visible: false,
          options: {},
        },
        {
          name: "actions",
          type: "actions",
          label: "Actions",
          visible: true,
          options: {
            actions: [
              {
                name: "edit",
                label: "Edit",
                icon: "edit",
                color: "primary",
                visible: (row) => {
                  return true;
                },
                action: (row) => {
                  this.appUserService.getById(row.id);
                },
              },
              {
                name: "delete",
                label: "Delete",
                icon: "delete",
                color: "warn",
                visible: (row) => {
                  return true;
                },
                action: (row) => {
                  this.appUserService.delete(row.id);
                },
              },
            ],
          },
        },
      ],
      data: data,
    };
    this.loadingDataTable = false;
  }

  stopObservables() {
    this.dataSub.unsubscribe();
  }
  loadData() {
    this.appUserService.getByAppId(this.idApp);
  }

  async loadExtraData() {
    this.userModel.getUsers().subscribe((data) => {
      this.users = data.map((r: IUser) => {
        return {
          label: r.name + " " + r.surname + "(" + r.username + ")",
          value: r.id,
        };
      });
    });
    this.roleService.getAll();
  }

  ngOnInit(): void {
    this.loadExtraData();
    this.startObservables();
    let that = this;
    setTimeout(function () {
      that.loadData();
    }, 500);
  }
}
