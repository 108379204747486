import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatOptionModule } from "@angular/material/core";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatSelectModule } from "@angular/material/select";
import { TranslateModule } from "@ngx-translate/core";
import { CgLoaderComponent } from "./components/cg-loader/cg-loader.component";
import { ModalDialogComponent } from "./components/modal-dialog/modal-dialog.component";
import { NotFoundComponent } from "./components/not-found/not-found.component";
import { MatTableModule } from "@angular/material/table";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatMenuModule } from "@angular/material/menu";

@NgModule({
    declarations: [CgLoaderComponent, ModalDialogComponent, NotFoundComponent],
    imports: [
        CommonModule,
        TranslateModule,
        MatDialogModule,
        MatProgressBarModule,
        MatIconModule,
        FormsModule,
        MatOptionModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        FlexLayoutModule,
        MatButtonModule,
        MatSelectModule,
        MatTableModule,
        MatSlideToggleModule,
        MatMenuModule
    ],
    exports: [
        CgLoaderComponent,
        ModalDialogComponent,
        NotFoundComponent,
        TranslateModule
    ],
    providers: [],
})
export class TmsToolsModule {}
