/*
 * File: api.service.ts                                                        *
 * Project: catalog-cloud                                                      *
 * File Created: Thursday, 17th February 2022 11:48:25                         *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Monday, 4th July 2022 14:06:07                               *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */

import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../../environment";
import { ApiParams } from "../models/api-param.model";
import { CookieService } from "ngx-cookie-service";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  private cc_jwt: string = "";
  private base_url: string = environment.base_url;
  constructor(private http: HttpClient,cookie:CookieService) {
    let orgCookie = cookie.get(environment.app_cookie);
    if ((typeof(orgCookie) !== 'undefined') && (orgCookie !== "")){
      let org = JSON.parse(orgCookie);
      this.base_url = org.backendUrl
    }
  }

  getFullUrl(endpoint: string) {
    var headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http.get<any>(endpoint, {
      headers: headers,
    });
  }

  get(endpoint: string, parameters?: ApiParams) {
    var headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");

    if (parameters != null && parameters.params.length > 0) {
      let params = new HttpParams();
      parameters.params.forEach((param) => {
        params = params.set(param.key, param.value);
      });
      return this.http.get<any>(this.base_url + endpoint, {
        headers: headers,
        params: params,
      });
    } else {
      return this.http.get<any>(this.base_url + endpoint, {
        headers: headers,
      });
    }
  }

  getFile(endpoint: string, parameters?: ApiParams) {
    var headers = new HttpHeaders();
    // headers.append('Content-Type', 'application/json');
    headers.append("Content-Type", "application/gzip");
    if (parameters != null && parameters.params.length > 0) {
      let params = new HttpParams();
      parameters.params.forEach((param) => {
        params = params.set(param.key, param.value);
      });
      return this.http.get(this.base_url + endpoint, {
        responseType: "blob",
      });
    } else {
      return this.http.get(this.base_url + endpoint, {
        headers: headers,
        responseType: "blob",
      });
    }
  }

  getWithBody(endpoint: string, data: any, parameters?: ApiParams) {
    var headers = new HttpHeaders();
    var body = data;
    headers.append("Content-Type", "application/json");

    if (parameters != null && parameters.params.length > 0) {
      let params = new HttpParams();
      parameters.params.forEach((param) => {
        params.append(param.key, param.value);
      });
      return this.http.get<any>(this.base_url + endpoint, {
        headers: headers,
        params: params,
        observe: body,
      });
    } else {
      return this.http.get<any>(this.base_url + endpoint, {
        headers: headers,
        observe: body,
      });
    }
  }

  post(endpoint: string, data: any, parameters?: ApiParams) {
    var body = data;
    if (parameters != null && parameters.params.length > 0) {
      let params = new HttpParams();
      parameters.params.forEach((param) => {
        params.append(param.key, param.value);
      });

      return this.http.post<any>(this.base_url + endpoint, body, {
        params: params,
      });
    } else {
      return this.http.post<any>(this.base_url + endpoint, body);
    }
  }

  put(endpoint: string, data: any, parameters?: ApiParams) {
    var body = data;
    if (parameters != null && parameters.params.length > 0) {
      let params = new HttpParams();
      parameters.params.forEach((param) => {
        params.append(param.key, param.value);
      });
      return this.http.put<any>(this.base_url + endpoint, body, {
        params: params,
      });
    } else {
      return this.http.put<any>(this.base_url + endpoint, body);
    }
  }

  patch(endpoint: string, data: any, parameters?: ApiParams) {
    var body = data;
    if (parameters != null && parameters.params.length > 0) {
      let params = new HttpParams();
      parameters.params.forEach((param) => {
        params.append(param.key, param.value);
      });
      return this.http.patch<any>(this.base_url + endpoint, body, {
        params: params,
      });
    } else {
      return this.http.patch<any>(this.base_url + endpoint, body);
    }
  }

  delete(endpoint: string) {
    return this.http.delete<any>(this.base_url + endpoint);
  }
}
