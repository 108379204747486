<!--
File: list.component.html
Project: catalog-cloud
File Created: Thursday, 3rd March 2022 15:43:37
Author: Tomás Muniesa (tomas@cege.es)
-----
Last Modified: Friday, 11th March 2022 18:58:51
Modified By: Tomás Muniesa (tomas@cege.es>)
-----
Copyright 2021 - 2022 Nousmedis, CeGe
-->


<div class="user-list-container">
  <button mat-raised-button color="primary" (click)="createUser()">
    Add new user
  </button>
  <div fxLayout="row" fxLayoutAlign="start start" fxFill>
    <app-table-generator
      *ngIf="!loading"
      [tableData]="tableUser"
      fxFlex="60"
    ></app-table-generator>
    <app-user-edit class="user-edit" fxFlex="30"></app-user-edit>
  </div>
</div>
