<!--
File: login.component.html
Project: catalog-cloud
File Created: Wednesday, 22nd December 2021 12:48:17
Author: Tomás Muniesa (tomas@cege.es)
-----
Last Modified:  Monday, 24th July 2023 2:48:20 pm
Modified By: Tomás (tomas@cege.es>)
-----
Copyright 2021 - 2022 Nousmedis, CeGe
-->

<div fxLayout="row" fxLayoutAlign="center center">
  <div fxFlex="50" class="login-background" fxLayoutAlign="center center">
    <img fxFlex="100" src="/assets/img/nousmedis_logo.png" />
  </div>
  <div  fxFlex="50" fxLayoutAlign="center center">
    <div class="login-container" *ngIf="isLogin">
      <!-- <mat-card-title> Inicio de sesión </mat-card-title> -->
      <mat-card-content *ngIf="!user">
        <div fxLayoutGap="20px grid">
          <div>
            <form [formGroup]="frm">
              <div fxFill>
                <mat-form-field appearance="outline" class="full-row">
                  <mat-label>{{ "USER.USER" | translate }} </mat-label>
                  <input
                    matInput
                    type="email"
                    matInput
                    #loginInput
                    formControlName="email"
                    [errorStateMatcher]="matcher"
                    placeholder="Ex. cc@nousmedis.com"
                  />
                  <mat-error>{{ "USER.INVALID_MAIL" | translate }} </mat-error>
                </mat-form-field>
              </div>
              <div fxFill>
                <mat-form-field appearance="outline" class="full-row">
                  <mat-label>{{ "USER.PASSWORD" | translate }} </mat-label>
                  <input
                    matInput
                    [type]="hide ? 'password' : 'text'"
                    [placeholder]="'USER.PASSWORD' | translate"
                    formControlName="password"
                  />
                  <mat-icon matSuffix (click)="hide = !hide">{{
                    hide ? "visibility_off" : "visibility"
                  }}</mat-icon>
                  <mat-error>
                    {{ "USER.VOID_PASSWORD" | translate }}
                  </mat-error>
                </mat-form-field>
                <mat-error *ngIf="badCredentials">
                  {{ "USER.BAD_LOGIN" | translate }}
                </mat-error>
              </div>

              <div fxFill>
                <button
                  mat-flat-button
                  color="primary"
                  (click)="tryLogin()"
                  class="full-row rounded mat-elevation-z2"
                  [disabled]=""
                  #BtnLogin
                >
                  {{ "USER.START_SESSION" | translate }}
                </button>
              </div>
            </form>
          </div>

          <div fxLayout="row" fxLayoutAlign="space-between center">
            <mat-checkbox color="primary" #chkRemember
              ><span class="remember-login">
                {{ "USER.REMEMBER" | translate }}</span
              ></mat-checkbox
            >
            <a [routerLink]="['/reset-password']" class="link-forget">{{
              "USER.FORGOT_PASS" | translate
            }}</a>
          </div>
          <div fxLayout="row" fxLayoutAlign="start start">
            <span>
              <a href="#" class="register-link">{{
                "USER.REGISTER" | translate
              }}</a>
            </span>
          </div>
          <div fxFill>
            <asl-google-signin-button
              type="standard"
              size="large"
              text="signin_with"
              width="400"
            ></asl-google-signin-button>
          </div>
        </div>
      </mat-card-content>
      <mat-card-content *ngIf="user">
        <div fxLayoutGap="20px grid">
          <div>
            {{ "USER.LOGGED_IN" | translate }}
            <button mat-raised-button color="primary" (click)="logout()">
              {{ "USER.CLOSE_SESSION" | translate }}
            </button>
          </div>
        </div>
      </mat-card-content>
    </div>

    <mat-card class="login-container" *ngIf="!isLogin">
      <mat-card-content>
        {{ errorLoginMessage | translate }}
      </mat-card-content>
    </mat-card>
  </div>
</div>
