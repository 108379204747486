import { Component, OnDestroy, OnInit } from "@angular/core";

import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from "@angular/forms";
import { AppForm } from "../../forms/app.form";
import { FormCreator } from "src/app/modules/form-generator/helpers/form-creator";
import { FormGeneratorService } from "src/app/modules/form-generator/services/form-generator.service";
import { AppsService } from "../../services/apps.service";
import { PopInfoService } from "src/app/modules/pop-info/services/pop-info-service.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Observer, Subscription } from "rxjs";
import { App } from "../../interfaces/app.interface";

@Component({
  selector: "app-app-form",
  templateUrl: "./app-form.component.html",
  styleUrls: ["./app-form.component.scss"],
})
export class FormComponent implements OnInit, OnDestroy {
  form: FormGroup;
  appForm: any = JSON.parse(JSON.stringify(AppForm)); //Deep copy without reference Important!
  fc: FormCreator;
  serviceSub: Subscription;
  dataLoaded: boolean = false;
  isNew: boolean = true;
  idApp: string = "";
  appName:string = "";

  constructor(
    private fb: FormBuilder,
    private fgService: FormGeneratorService,
    private appService: AppsService,
    private popup: PopInfoService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.fc = new FormCreator(fb);
  }
  ngOnDestroy(): void {
    this.serviceSub.unsubscribe();
  }

  startObservables() {
    this.serviceSub = this.appService.$app.subscribe((app: App) => {
      this.dataLoaded = false;
      this.appForm = this.fc.fillFormSchemaWithData(
        JSON.parse(JSON.stringify(AppForm)),
        app
      );
      let id = this.appForm.groups[0].fields.filter((field) => {
        return field.key == "id";
      })[0].value;
      let name = this.appForm.groups[0].fields.filter((field) => {
        return field.key == "name";
      })[0].value;
      this.idApp = id;
      this.appName = name ? name : "New App"; 
      this.isNew = id ? false : true;
      this.dataLoaded = true;
      this.appService.reloadAppUserForm.next(true)
    });

    this.fgService.form$.subscribe((form) => {
      if (form.app) {
        const data = form.app;

        if (data.id) {
          this.appService
            .update(data.id, data)
            .then((res) => {
              this.popup.addMessage("Info", "App updated", "success");
              this.appService.getAll();
            })
            .catch((err) => {
              this.popup.addMessage("Error", "Error updating app", "error");
            });
        } else {
          this.appService
            .create(data)
            .then((res) => {
              this.popup.addMessage("Info", "App created", "success");
              this.appService.getAll();
            })
            .catch((err) => {
              this.popup.addMessage("Error", "Error updating app", "error");
            });
        }
      }
    });
  }


  ngOnInit(): void {
    this.startObservables();
    // this.checkIfNew();
  }
}
