import { Component, OnDestroy, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
// import { IUser } from "./modules/user/interfaces/user.interface";
import { langs } from "src/assets/i18n/available-lang";
import { CookieService } from "ngx-cookie-service";
import { SideMenuService } from "./modules/menus/services/sideMenu.service";
import { expand, Subscription } from "rxjs";
import { UserModel } from "./modules/user/models/user.model";
import { ApiService } from "./modules/api/services/api.service";
import { IUser } from "./modules/user/interfaces/user.interface";
import { AuthModel } from "./modules/user/models/auth.model";
import { UserService } from "./modules/user/services/user.service";

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
    title = "syncwall-client";
    // user: IUser;

    expandedSideMenu: boolean = true;
    sideNavExpansion$: Subscription;
    // usrService: UserService;
    user$: Subscription;
    user: IUser | undefined;
    logOut: boolean = true;
    userModel: UserModel;
    auth: AuthModel;

    constructor(
        public translate: TranslateService,
        // userService: UserService,
        private cookie: CookieService,
        private sideMenuService: SideMenuService,
        private userService: UserService,
        api: ApiService
    ) {
        this.userModel = new UserModel(api, cookie);
        this.auth = new AuthModel(api, cookie);
        // this.usrService = userService;
        // this.user = userService.setUser();
        this.user = this.auth.getCurrentUser();
        if (!this.user || this.user.id == "") this.logOut = false;

        translate.addLangs(
            langs.map((l) => {
                return l.value;
            })
        );

        // console.log(this.user.lang);
        // Set default language
        if (this.user && this.user.lang && this.user.lang.length > 0)
            translate.setDefaultLang(this.user.lang);
        else translate.setDefaultLang("es_ES");
    }
    ngOnDestroy(): void {
        this.user$.unsubscribe();
    }

    ngOnInit(): void {
        this.sideCollapsed();
        this.observerUser();
    }

    sideCollapsed() {
        this.sideNavExpansion$ = this.sideMenuService.isExpanded.subscribe(
            (expand) => {
                this.expandedSideMenu = expand;
            }
        );
    }

    observerUser() {
        this.user$ = this.userService.user$.subscribe((user) => {
            this.user = user;
        });
    }
}
