import { Component, OnInit } from "@angular/core";
import { OrganitzationService } from "../../services/organitzation.service";

@Component({
  selector: "app-organitzations",
  templateUrl: "./organitzations.component.html",
  styleUrls: ["./organitzations.component.scss"],
})
export class OrganitzationsComponent implements OnInit {
  constructor(private orgService: OrganitzationService) {}

  ngOnInit(): void {}

  newOrg() {
    this.orgService.init();
  }
}
