<!--
 * File: app.component.html                                                    *
 * Project: @nousmedis/guard-front                                             *
 * File Created: Thursday, 9th March 2023 11:12:44                             *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Thursday, 9th March 2023 15:40:18                            *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2023 Nousmedis, CeGe                                       *
 -->



<ng-container *ngIf="user">
    <cc-top-menu></cc-top-menu>
    <mat-sidenav-container>
        <mat-sidenav
            #sidenav
            [opened]="true"
            [mode]="'side'"
            class="main-sidenav"
            [ngClass]="expandedSideMenu ? 'sidemenu-expanded' : 'sidemenu'"
        >
            <nm-side-menu></nm-side-menu>
        </mat-sidenav>
        <mat-sidenav-content
            class="sidenav-content"
            [ngClass]="
                expandedSideMenu ? 'content-collapsed' : 'content-expanded'
            "
        >

            <router-outlet class="router-outlet"></router-outlet>
        </mat-sidenav-content>
    </mat-sidenav-container>
</ng-container>
<ng-container *ngIf="!user">
    <router-outlet></router-outlet>
</ng-container>
