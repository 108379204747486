
<mat-tab-group *ngIf="appName">
  <mat-tab [label]="appName">
    <app-generate-form
      *ngIf="dataLoaded"
      [jsonForm]="appForm"
    ></app-generate-form>
  </mat-tab>

  <mat-tab *ngIf="!isNew" label="Permissions">
    <app-app-user [idApp]="idApp"></app-app-user>
  </mat-tab>
</mat-tab-group>
