import { FormSchema } from "../../form-generator/interfaces/form-schema.interface";

export const AppUserForm: FormSchema = {
  groups: [
    {
      groupName: "apptouser",
      groupLabel: "App to User",
      showLabel: true,
      fields: [
        {
          type: "hidden",
          label: "ID",
          value: "",
          key: "id",
          required: true,
          order: 1,
        },
        {
          type: "hidden",
          label: "ID App",
          value: "",
          key: "idApp",
          required: true,
          order: 2,
        },
        {
          type: "select",
          label: "ID User",
          value: "",
          key: "idUser",
          required: true,
          order: 3,
          data: {
            base: {},
            selected: "idUser",
          },
        },
        {
          type: "select",
          label: "ID Role",
          value: "",
          key: "idRole",
          required: true,
          order: 4,
          data: {
            base: {},
            selected: "idUser",
          },
        },
      ],
    },
  ],
};
