/*
 * File: auth.model.ts                                                         *
 * Project: @nousmedis/guard-front                                             *
 * File Created: Friday, 25th November 2022 13:19:33                           *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Friday, 10th March 2023 13:42:29                             *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2023 Nousmedis, CeGe                                       *
 */

import { Injectable } from "@angular/core";
import { CookieService } from "ngx-cookie-service";
import { ApiService } from "../../api/services/api.service";
import { IUser } from "../interfaces/user.interface";
import jwt_decode from "jwt-decode";
import { environment } from "src/environment";
import { Subject } from "rxjs";

export class AuthModel {
  user: IUser;
  private jwtCookie = "NMGUARD-JWT";
  private jwtCookieRole = "NMGUARD-ROLE";


  changeCookie: Subject<boolean> = new Subject<boolean>();

  constructor(private api: ApiService, private cookie: CookieService) {}

  private urls = {
    login: "users/login",
    loginProvider: "users/loginprovider",
    jwtRefresh: "users/jwt-refresh",
  };

  public decodeUserId(token: string) {
    if (token) {
      var jwt_data: any = jwt_decode(token);
      return jwt_data["id"];
    } else {
      return undefined;
    }
  }

  public decodeToken(token: string) {
    return jwt_decode(token);
  }

  public isExpired(jwt: string) {
    return this.decodeToken(jwt);
  }

  public setJwsTokenCookie(token: string) {
    let userToken: any = {};
    userToken.token = token;
    let strToken = JSON.stringify(userToken);
    this.cookie.set(
      this.jwtCookie,
      strToken,
      0,
      "/",
      environment.base_domain,
      true
    );

    // localStorage.setItem("refresh_token", refresh_token);
  }

  public setRoleCookie(code: string, idUSer: string) {
    let cookie: any = {code:code, idUser:idUSer};
    let strToken = JSON.stringify(cookie);
    this.cookie.set(
      this.jwtCookieRole,
      strToken,
      0,
      "/",
      environment.base_domain,
      true
    );
  }

  public getRoleCode(){
    if(this.cookie.check(this.jwtCookieRole)){
      let cookie = JSON.parse(this.cookie.get(this.jwtCookieRole));
      return cookie.code;
    }
    return undefined;
  }

  public isLogged(): boolean {
    if (this.cookie.check(this.jwtCookie)) {
      try {
        let token = JSON.parse(this.cookie.get(this.jwtCookie))["token"];
        let id: string = this.decodeUserId(token);
        if (id.length > 1) {
          //this.logoOut.next(false);
          return true;
        } else return false;
      } catch (e) {
        return false;
      }
    } else return false;
  }

  public setUser() {
    if (this.cookie.check(this.jwtCookie)) {
      this.user = JSON.parse(this.cookie.get(this.jwtCookie));
    } else {
      //  this.reset();
    }
    return this.user;
  }

  public getCurrentUser(): IUser | undefined {
    if (!this.cookie.check(this.jwtCookie)) {
      return undefined;
    }
    let cookie = JSON.parse(this.cookie.get(this.jwtCookie));
    let token: any = this.decodeToken(cookie.token);
    if (typeof token.data === "string") return JSON.parse(token.data);
    else return token.data;
  }

  public getCookie(): string {
    let cookieData = JSON.parse(this.cookie.get(this.jwtCookie));
    let tokens = {
      token: cookieData.token,
      refreshToken: localStorage.refreshToken,
    };
    return tokens.token;
  }

  public tryLogin(user: string, pass: string) {
    let data = {
      username: user,
      password: pass,
    };
    return this.api.post(this.urls.login, data);
  }

  public refreshToken() {
    let data = JSON.parse(this.cookie.get(this.jwtCookie)).refreshToken;
    return this.api.post(this.urls.jwtRefresh, { body: data });
  }

  public resetCookie() {
    if (this.cookie.check(this.jwtCookie)) {
      this.cookie.delete(this.jwtCookie);
    }
    localStorage.clear();
    this.changeCookie.next(false);
  }

  public logOut() {
    this.user = undefined;
    this.resetCookie();
  }

  public loginProvider(user: IUser) {
    return this.api.post(this.urls.loginProvider, { body: user });
  }
}
