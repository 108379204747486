import { Injectable } from "@angular/core";

import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class FormGeneratorService {
  private _form = new Subject<any>();
  form$ = this._form.asObservable();

  constructor() {}

  setForm(form: any) {
    this._form.next(form);
  }

 
}
