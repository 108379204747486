<table
  mat-table
  [dataSource]="data"
  class="mat-elevation-z8"
  id="{{ tableName }}"
  fxFlex="100"
>
  <ng-container *ngFor="let column of columns" [matColumnDef]="column.name">
    <th mat-header-cell *matHeaderCellDef>{{ column.label }}</th>
    <td mat-cell *matCellDef="let element">
      <ng-container [ngSwitch]="column.type">
        <span *ngSwitchDefault>
          <span *ngIf="column.options?.convert">
            {{ column.options?.convert(element) }}</span
          >
          <span *ngIf="!column.options?.convert">
            {{ element[column.name] }}</span
          ></span
        >
        <span *ngSwitchCase="'date'">
          {{ element[column.name] | date : "dd/MM/yyyy" }}
        </span>
        <span *ngSwitchCase="'datetime'">
          {{ element[column.name] | date : "dd/MM/yyyy HH:mm" }}
        </span>
        <span *ngSwitchCase="'time'">
          {{ element[column.name] | date : "HH:mm" }}
        </span>
        <span *ngSwitchCase="'boolean'">
          <mat-slide-toggle
            [checked]="element[column.name]"
            [disabled]="true"
          ></mat-slide-toggle>
        </span>
        <span *ngSwitchCase="'boolean-button'">
          <mat-slide-toggle
            [checked]="element[column.name]"
            (click)="column.options.action(element)"
          ></mat-slide-toggle>
        </span>
        <div *ngSwitchCase="'actions'">
          <ng-container *ngFor="let action of column.options.actions">
            <button
              *ngIf="action.visible(element)"
              mat-icon-button
              [color]="action.color"
              (click)="action.action(element)"
            >
              <mat-icon>{{ action.icon }}</mat-icon>
            </button>
          </ng-container>
        </div>
      </ng-container>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
