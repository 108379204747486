import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { Observer, Subscription } from "rxjs";
import { SideMenuService } from "src/app/modules/menus/services/sideMenu.service";
import menuData from "src/assets/data/side-menu.json";
import { SideMenu } from "../../interfaces/side-menu.interface";
import { CookieService } from "ngx-cookie-service";
import { environment } from "src/environment";
import { ApiService } from "src/app/modules/api/services/api.service";
import { AuthModel } from "src/app/modules/user/models/auth.model";
@Component({
  selector: "nm-side-menu",
  templateUrl: "./side-menu.component.html",
  styleUrls: ["./side-menu.component.scss"],
})
export class SideMenuComponent implements OnInit {
  menuItems: SideMenu[] = menuData;
  expanded: boolean = true;
  expanded$: Subscription;
  userRole: string;
  auth: AuthModel;
  @Output() isExpanded = new EventEmitter<boolean>();
  constructor(
    private sideMenuService: SideMenuService,
    private api: ApiService,
    cookies: CookieService
  ) {
    this.userRole = cookies.get(environment.app_cookie);
    this.auth = new AuthModel(api, cookies);
    this.userRole = this.auth.getRoleCode();
  }

  ngOnInit(): void {
    this.lookForExpansion();
  }

  lookForExpansion() {
    this.expanded$ = this.sideMenuService.isExpanded.subscribe((expansion) => {
      this.expanded = expansion;
    });
  }
}
